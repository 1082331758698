import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Reservation {

    static async getAllListeStagiaireByStage(token, year) {
        const res = await axios.post(api_endpoint + '/admin/reservations/getStagiaires?year=' + year, {
            token
        });
        return res.data;
    }

    static async updateReservation(token, id, infos) {
        const res = await axios.post(api_endpoint + '/admin/reservations/' + id + '/update', {
            token, infos
        });
        return res.data;
    }

    static async getInformationsStagiairesForExcel(token, numsemaine) {
        const res = await axios.post(api_endpoint + '/admin/reservations/getInfosStagiaires/' + numsemaine, {
            token
        });
        return res.data;
    }


}

export default Reservation;