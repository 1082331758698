import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "./creator-form.module";
import Commande from "../controller/commandes.controller";
import styles from './panier.module.css'
import { useNavigate } from "react-router-dom";
import { Global } from "../App";
import convertISODate from "../Utilitaire/date";
import Reservation from "../controller/reservations.controller";
import ReactLoading from 'react-loading';
import { UilTrashAlt, UilTag, UilLabelAlt, UilPricetagAlt } from '@iconscout/react-unicons';
import HeaderStep from "../ScreenPart/header-step.module";
import SignPopup from "../ScreenPart/sign-popup.module";

function Panier(props) {

    const [isLoading, setIsLoading] = useState(true);

    const { nextStep, parameters, order } = useContext(FormContext);
    const [reservations, setReservations] = useState([]);
    const [total, setTotal] = useState(0);
    const [reduction, setReduction] = useState(0);
    const [popUpOpen, setPopUpOpen] = useState(false);

    const navigate = useNavigate();

    const addNewStage = function() {
        navigate('/creator');
    }

    const refresh = () => {
        if(localStorage.getItem('codecommande')) {
            Commande.getReservations(localStorage.getItem('codecommande')).then((res) => {
                setReservations(res);
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(false);
        }
    }

    useEffect(refresh, []);

    useEffect(() => {
        let montantTotal = 0;
        let reduction = 0;
        reservations.forEach((r, i) => {
            montantTotal += parseFloat(r.prixtotal);
            if(i > 0) {
                montantTotal -= 10;
                reduction += 10;
            }
        });
        setReduction(reduction);
        setTotal(montantTotal);
    }, [reservations]);

    let listResa = reservations.map(r => 
        <tr>
            <td>{r.nom.toUpperCase() + " " + r.prenom}</td>
            <td>{convertISODate(r.datedebut).toUpperCase()} - {convertISODate(r.datefin).toUpperCase()}</td>
            <td>{r.libelle_stage}</td>
            <td>{r.libelle_option}</td>
            <td>{r.prixtotal}€</td>
            <td><a onClick={() => Reservation.deleteReservations(localStorage.getItem('codecommande'), r.idreservation).then(() => refresh())}><UilTrashAlt /></a></td>
        </tr>
        
    );


    return (<>
        <HeaderStep title={"Votre panier"}></HeaderStep>

        <div className="root">
            <p className="explication">Consultez votre panier. Vous pouvez dès maintenant procéder au paiement, ou ajouter un nouveau stage à votre panier.</p>
            
            { isLoading ? <ReactLoading type={"balls"} color={"#2197B1"} height={50} width={50} /> : null}
            { !localStorage.getItem('codecommande') || !isLoading & reservations.length == 0 ? <p>Le panier est actuellement vide.</p> : <>

                <div className={styles.panier}>
                    <div>
                        <table>
                            <tr>
                                <th>Nom Prénom</th>
                                <th>Date</th>
                                <th>Stage</th>
                                <th>Option</th>
                                <th>Prix</th>
                                <th></th>
                            </tr>
                            { listResa }
                        </table>
                    </div>
                    { reduction > 0 ? 
                    <p className={styles.reduction}><UilPricetagAlt />  PROMO : -{reduction}€</p> : null }
                    <p className={styles.prixtotal}>TOTAL : { total }€ </p>
                </div>

                { popUpOpen ? <SignPopup continu={() => navigate('/confirmation')} close={() => setPopUpOpen(false)} /> : null}

            </>

        }

            <div className={styles.choix}>

                <button class="actionButton" onClick={() =>  setPopUpOpen(true)} disabled={(reservations.length === 0)}>Confirmer la commande</button>
                <button class="actionButton" onClick={() => addNewStage() }>Ajouter un nouveau stage</button>

            </div>
        </div>
    </>);

}

export default Panier;