import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Stage {

    static async getAllStages() {
        const res = await axios.get(api_endpoint + '/stages');
        return res.data;
    }

    static async createStage(token, libelle) {
        const res = await axios.post(api_endpoint + '/admin/stages/create',
            {token, libelle});
        return res.data.idstage;
    }

    static async getStageById(id) {
        const res = await axios.get(api_endpoint + '/stages/' + id);
        return res.data;
    }

    static async getOptions(idstage) {
        const res = await axios.get(api_endpoint + '/stages/' + idstage + '/options');
        return res.data;     
    }

    static async getStockByWeek(id, year) {
        const res = await axios.get(api_endpoint + '/stages/' + id + '/stock?year=' + year);
        return res.data;
    }

    static async updateStage(token, infos) {
        const res = await axios.post(api_endpoint + '/admin/stages/' + infos.idstage + '/update',
            {token, ...infos});
        return res.data;
    }

    static async deleteStage(token, id) {
        const res = await axios.post(api_endpoint + '/admin/stages/' + id + '/delete',
            {token});
        return res.data;
    }

    static async setStock(token, idstage, stock) {
        const res = await axios.post(api_endpoint + '/admin/stages/' + idstage + '/update/stock',
            {token, stock});
        return res.data;
    }

    static async updateOptions(token, idstage, packs) {
        const res = await axios.post(api_endpoint + '/admin/stages/' + idstage + '/update/options',
            {token, packs});
        return res.data;    
    }

    static async getAllStagesWithDispo(week) {
        const res = await axios.get(api_endpoint + "/stages?week=" + week);
        return res.data;
    }
}

export default Stage;