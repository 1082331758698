import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Semaine {

    static async getWeeks(year = new Date().getFullYear()) {
        const res = await axios.get(api_endpoint + '/semaines?year=' + year);
        return res.data;        
    }

    static async createWeek(token, datedebut, datefin) {
        const res = await axios.post(api_endpoint + '/admin/semaines/create', {
            token,
            datedebut,
            datefin
        });
        return res.data;      
    }
}

export default Semaine;