import React, { useContext } from "react";
import Option from "../controller/options.controller";
import { useEffect, useState } from 'react';

import styles from "./list-options.module.css";
import convertISODate from "../Utilitaire/date";
import Options from "../components/options.module";
import { FormContext } from "../screens/creator-form.module";
import { Global } from "../App";

import ReactLoading from 'react-loading';

function ListOptions(props) {

    const { nextStep } = useContext(FormContext);
    const { showError } = useContext(Global);

    const [isLoading, setIsLoading] = useState(true);

    const [options, setOptions] = useState([]);


    useEffect(() => {
        Option.getOptionsAvailables(props.stage.idstage, props.stage.numsemaine).then((res) => {
            setOptions(res);
            setIsLoading(false);
        });

    }, []);



    let listOptions = options.map((o) => 
        <Options option={o} stage={props.stage} onclick={props.onchoice}></Options>
    );

    if(isLoading) {
        listOptions = (<ReactLoading type={"balls"} color={"#2197B1"} height={50} width={50} />);
    }
    else if(listOptions.length === 0) {
        listOptions = (<p className="error">Il n'y a aucune option disponible avec ce stage.</p>)
    }



    return (
        <div className={styles.root}>
            <div className={styles.list}>
                {listOptions}
            </div>
        </div>
    );
}

export default ListOptions;