import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Client{

    static async getClientById(token, id) {
        const res = await axios.post(api_endpoint + '/admin/clients/getId/' + id, {
            token
        });
        return res.data;
    }
    
    static async updateClient(token, id, datas) {
        const res = await axios.post(api_endpoint + '/admin/clients/update/' + id, {
            token,
            datas
        });
        return res.data;
    }

}

export default Client;