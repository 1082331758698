import React, { useContext, useEffect, useState } from "react";
import Popup from "./popup.module";
import Semaine from "../backoffice/controller/semaine";
import convertISODate from "../Utilitaire/date";
import InputForm from "./inputform.module";
import { AdminPanelContext } from "../backoffice/screens";

function WeekPopupSelector(props) {

    const [weeks, setWeeks] = useState([]);
    const [newWeekPopup, setNewWeekPopup] = useState(false);
    const [newWeek, setNewWeek] = useState({});
    const [weekSelected, setWeekSelected] = useState({});

    const { getToken } = useContext(AdminPanelContext);

    useEffect(function () {
        Semaine.getWeeks().then(w => {
            setWeeks(w);
            setWeekSelected(w[0]);
        });
    }, []);

    return (<><Popup title="Sélection de la semaine" close={props.close}>
        <select style={{ width: "90%" }} value={weekSelected.numsemaine} onChange={(e) => {
            setWeekSelected(weeks.find(i => i.numsemaine == e.target.value));
        }}>
            {weeks.map(w => 
                <option value={w.numsemaine}>{ convertISODate(w.datedebut).toUpperCase() } - { convertISODate(w.datefin).toUpperCase() }</option>    
            )}
        </select>
        <a onClick={(() => setNewWeekPopup(true))}>+ AJOUTER UNE SEMAINE</a>
        <button type="button" className="actionButton" onClick={() => {
            props.close();
            props.onResponse(weekSelected);
        }}>SUIVANT</button>
    </Popup>

    {showNewWeekPopup()}
    
    </>);

    function newWeekController(libelle, value) {
        const tmp = newWeek;
        tmp[libelle] = value;
        setNewWeek({...tmp});
    }

    async function createWeek() {
        const res = await Semaine.createWeek(getToken(), newWeek.datedebut, newWeek.datefin);
        const numsemaine = res.numsemaine;

        const weeks = await Semaine.getWeeks();
        setWeeks(weeks);
        setNewWeekPopup(false);
    }

    function showNewWeekPopup() {
        if(newWeekPopup) {
            return (
                <Popup close={() => setNewWeekPopup(false)} title="Création nouvelle semaine">
                    <InputForm type="date" libelle="Date de début" name="datedebut" placeholder="12/07/2024"  controller={ newWeekController } required={true}></InputForm>
                    <InputForm type="date" libelle="Date de fin" name="datefin" placeholder="16/07/2024"  controller={ newWeekController } required={true}></InputForm>
                    <button type="button" className="actionButton" onClick={() => createWeek()}>ENREGISTRER</button>
                </Popup>
            )
        }
    }
}

export default WeekPopupSelector;