import axios from 'axios';

const api_endpoint = require('../config').api_endpoint;
//const api_endpoint = "http://10.192.62.84:3001/api";

class Reservation {

    static async createReservation(codecommande, stagiaire, numsemaine, idstage, idoption) {
        const res = await axios.post(api_endpoint + '/reservations/create/' + codecommande, {
            stagiaire, numsemaine, idstage, idoption
        });
        return res.data;
    }

    static async deleteReservations(codecommande, idresa) {
        const res = await axios.delete(api_endpoint + '/reservations/' + idresa + '/' + codecommande);
        return res.data;    
    }


    static async getReservation(code) {
        const res = await axios.get(api_endpoint + '/reservations/' + code);
        return res.data;
    }

    static async eTransactionDatas(code, prix) {
        const res = await axios.get(api_endpoint + '/paiements/card/create/' + code + '/' + prix);
        return res.data;
    }

    static async getPaiementsConfirmed(code) {
        const res = await axios.get(api_endpoint + '/paiements/' + code);
        return res.data;
    }

}

export default Reservation;