
import React from "react";
import style from "./stages.module.css";
import { UilShoppingCart } from '@iconscout/react-unicons'
import { api_endpoint } from "../config";


const jourWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

function Stages(props) {

    const click = function() {
        props.onclick(props.stage);
    }
    

    return (<>
        
        <div onClick={props.stage.nbplaces > 0 && props.stage.forcecomplet == false ? click : null} className={style.root} style={{ backgroundImage: "url(" + '/uploads/' + props.stage.image + ")"}}>
            
            {props.stage.nbplaces == 0 || props.stage.forcecomplet == true ? <div className={style.soldout}>
                <p className={style.soldouttext}>COMPLET*</p>
                <p className={style.soldoutcpt}>*Des places peuvent se libérer, contacter le club au 04 79 25 21 66 pour plus d'informations.</p>
            </div> : null }

            <div className={style.image} style={{ backgroundImage: "url(" + api_endpoint +  '/uploads/get/' + props.stage.image + ")"}}>
            </div>
            <main>
                <div className={style.headerInfo}>
                    <h3>{props.stage.libelle}</h3>
                    <p className={style.dispoInfo}>{props.stage.nbplaces > 0 && props.stage.forcecomplet == false ? <><b>{props.stage.nbplaces}</b> places restantes </>: <b>COMPLET</b>}</p>
                </div>
                <div className={style.tagList}>
                    <p className={style.tag}>{props.stage.supports.toUpperCase()}</p>
                    <p className={style.tag}>{props.stage.niveau.toUpperCase()}</p>
                    <p className={style.tag}>
                        {(props.stage.agemin == 18 && props.stage.agemax == 100) ? "A PARTIR DE 18 ANS" :
                        <>{props.stage.agemin}-{props.stage.agemax} ANS</>}
                    </p>
                </div>
                
                <p>{props.stage.description}</p>

                <div className={style.footerInfo}>
                    <div className={style.momentInfo}>
                        <p className={style.horaireInfo}>{props.stage.horairedebut.substring(0, 5)} - {props.stage.horairefin.substring(0, 5)}</p>
                        <p className={style.jourInfo}>{jourWeek[props.stage.jourdebut - 1]} au {jourWeek[props.stage.jourfin - 1]}</p>
                    </div>
                    <p className={style.prixInfo}>{props.stage.prix} €</p>
                </div>
            </main>
            
        </div>
        
    </>);
}

export default Stages;