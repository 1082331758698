import React, { useEffect, useState } from "react";
import styles from './sign-popup.module.css';

function SignPopup(props) {

    const [isValid, setIsValid] = useState(false);
    const [highlight, setHighlight] = useState(false);


    useEffect(() => {
        if(isValid) {
            setHighlight(false);
        }
    }, [isValid]);

    return (<>
        <div className={styles.background} onClick={props.close}></div>
        <div className={styles.popup}>
            <p class="explication">Pour continuer, vous devez accepter les conditions suivantes en cochant les cases.</p>
            <div className={styles.documentBox}><input className={styles.checkbox + " " + (highlight ? styles.high : null)} onClick={(e) => {
                setIsValid(e.target.checked);
            }} type="checkbox"></input><div><p className={styles.libelle}>Conditions générales de vente <a className={styles.downloadBtn} target="_blank" href="/CGV_YCBL.pdf"> CONSULTER LE DOCUMENT</a></p><p className={styles.description}>En cochant cette case, vous acceptez les conditions générales de vente.</p></div></div>
            <div className={styles.btnChoix}>
                <a onClick={props.close}>◀︎ RETOUR</a>
                <button onClick={props.continu} onMouseOver={() => {
                    if(!isValid) {
                        setHighlight(true);
                    }
                    else {
                        setHighlight(false);
                    }
                }} class="actionButton" disabled={!isValid}>Continuer</button>
            </div>
        </div>
    </>)

}

export default SignPopup;