import React, { useContext, useEffect } from "react";
import { useState } from "react";
import HeaderStep from "../ScreenPart/header-step.module";
import Weeks from "../components/weeks.module";
import Week from "../controller/weeks.controller";
import { FormContext } from "./creator-form.module";
import { Global } from "../App";

import ReactLoading from 'react-loading';

function WeeksChoice(props) {

    const [weeks, setWeeks] = useState([]);

    const { parameters, nextStep } = useContext(FormContext);
    const { showError } = useContext(Global);

    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedWeeks, setSelectedWeeks] = useState([]);

    useEffect(() => {
        setIsValid(selectedWeeks.length > 0);
    }, [selectedWeeks]);

    const selectWeek = function (w) {
        setSelectedWeeks([...selectedWeeks, w]);
    }

    const deselectWeek = function (w) {
        let s = selectedWeeks;
        s = s.filter(i => i !== w);
        setSelectedWeeks(s);

    }

    useEffect(() => {
        Week.getWeeks().then((res) => {
            // On affiche uniquement les semaines à venir
            res = res.filter(w => new Date(w.datedebut) >= new Date());
            
            setWeeks(res);
            setIsLoading(false);
        }).catch(err => { showError("Une erreur est survenue !!!") });
    }, []);

    

    let listWeeks = weeks.map((w) => 
        <Weeks week={w} onSelect={selectWeek} onDeselect={deselectWeek}></Weeks>
    );


    if(isLoading) {
        listWeeks = (<ReactLoading type={"balls"} color={"#2197B1"} height={50} width={50} />);
    }
    

    return (
        <div className="root">

            <p className="explication">Sélectionnez les semaines pour lesquelles vous souhaitez consulter les offres disponibles. Choisissez en fonction de vos disponibilités. Vous devez sélectionner au moins une semaine.</p>


            <div style={{ width: "75%", margin: "0 auto", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                {listWeeks}
            </div>

            <button className="actionButton" onClick={() => nextStep(selectedWeeks) } disabled={!isValid}>SUIVANT</button>
        </div>

    );

}

export default WeeksChoice;
