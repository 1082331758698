import React, { useContext, useEffect, useState } from "react";
import Stage from "../controller/stages";
import styles from "./stages-list.module.css"
import { AdminPanelContext } from ".";
import Option from "../controller/options";
import { useNavigate } from "react-router-dom";
import { api_endpoint } from "../../config";
import Popup from "../../components/popup.module";
import InputForm from "../../components/inputform.module";
import Commande from "../controller/commande";
import { UilEnvelopeCheck } from '@iconscout/react-unicons'
import { UilEnvelopeTimes } from '@iconscout/react-unicons'

function PaiementsToConfirm(props) {

    const { setTitle, getToken } = useContext(AdminPanelContext);
    setTitle("Paiements en attente");

    let paiement_code = {
        "CARD": "CARTE BANCAIRE",
        "CARD_ONLINE": "CARTE BANCAIRE",
        "CASH": "ESPECES",
        "TRANSFER": "VIREMENT BANCAIRE",
        "CHEQUE": "CHEQUES",
        "CHEQUEHOLY": "CHEQUE VACANCES",
        "REFUND": "REMBOURSEMENT",
        "REDUC_ADH": "REDUCTION ADHERENT",
        "REDUC_PERSO": "REDUCTION PERSONNALISEE",
        "BON_CADEAU": "BON CADEAU",
    }

    const [datas, setDatas] = useState([]);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [paiementToConfirm, setPaiementToConfirm] = useState({});

    const [cancelPopup, setCancelPopup] = useState(false);
    const [paiementToCancel, setPaiementToCancel] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        Commande.getPaiementToBeConfirmed(getToken()).then((res) => {
            setDatas(res);
        });
    }, []);

    const confirmPaiement = (d) => {
        setConfirmPopup(true);
        setPaiementToConfirm(d);
    }

    const cancelPaiement = (d) => {
        setCancelPopup(true);
        setPaiementToCancel(d);
    }

    return (<>
        <div className={styles.root}>
            <h1>Liste des paiements en attente</h1>

            <table>
                <tr>
                    <th>Réf. commande</th>
                    <th>Client</th>
                    <th>Date paiement</th>
                    <th>Moyen de paiement</th>
                    <th style={{ maxWidth: '200px' }}>Infos complémentaires</th>
                    <th>Montant</th>
                    <th></th>
                    <th></th>
                </tr>
                { datas.length == 0 && <tr><td colSpan="6">Aucun paiement en attente.</td></tr>}

                {
                    datas.map((d) => {
                        return(
                            <tr className={styles.tableHover}>
                                <td style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                                    navigate('/admin/commandes/details/' + d.idcommande);
                                }}>{d.refcommande}</td>
                                <td>{d.nom.toUpperCase() + ' ' + d.prenom}</td>
                                <td>{new Date(d.date).toLocaleString()}</td>
                                <td>{paiement_code[d.moyenpaiement]}</td>
                                <td style={{ maxWidth: '200px' }}>{d.infos}</td>
                                <td>{d.prix} €</td>
                                <td><a  style={{ color: 'darkgreen'}} onClick={() => confirmPaiement(d)}><UilEnvelopeCheck /> &nbsp;CONFIRMER</a></td>
                                <td><a style={{ color: 'darkred'}} onClick={() => cancelPaiement(d)}><UilEnvelopeTimes /> &nbsp;ANNULER</a></td>

                            </tr>
                        );
                    })
                }
            </table>

        </div>

        {
            confirmPopup ? <Popup title="Confirmer le paiement" close={() => setConfirmPopup(false)}>
                <div style={{ width: "90%"}}>
                    <p style={{ margin: 20, fontSize: '14pt'}}>Confirmer le paiement de <b>{paiementToConfirm.prix}</b> € en <b>{paiement_code[paiementToConfirm.moyenpaiement]}</b> ({paiementToConfirm.infos}) pour la commande <b>{paiementToConfirm.refcommande}</b> au nom de <b>{paiementToConfirm.nom.toUpperCase()} {paiementToConfirm.prenom}</b> ?</p>
                </div> 
                <button type="button" className="actionButton" onClick={() => {
                    Commande.confirmPaiement(getToken(), paiementToConfirm.refpaiement).then(() => {
                        setConfirmPopup(false);
                        Commande.getPaiementToBeConfirmed(getToken()).then((res) => {
                            setDatas(res);
                        });
                    });
                }}>CONFIRMER</button>    

            </Popup> : null
                
        }

        {
            cancelPopup ? <Popup title="Annuler le paiement" close={() => setCancelPopup(false)}>
                <div style={{ width: "90%"}}>
                    <p style={{ margin: 20, fontSize: '14pt'}}>Annuler le paiement de <b>{paiementToCancel.prix}</b> € en <b>{paiement_code[paiementToCancel.moyenpaiement]}</b> ({paiementToCancel.infos}) pour la commande <b>{paiementToCancel.refcommande}</b> au nom de <b>{paiementToCancel.nom.toUpperCase()} {paiementToCancel.prenom}</b> ? Cette action est irréversible !</p>
                </div> 
                <button type="button" className="actionButtonNegative" onClick={() => {
                    Commande.cancelPaiement(getToken(), paiementToCancel.refpaiement).then(() => {
                        setCancelPopup(false);
                        Commande.getPaiementToBeConfirmed(getToken()).then((res) => {
                            setDatas(res);
                        });
                    });
                }}>ANNULER LE PAIEMENT</button>    

            </Popup> : null
                
        }

        </>
    );

}

export default PaiementsToConfirm;