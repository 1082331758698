

import React, { useContext, useEffect, useState } from "react";
import Stage from "../controller/stages";
import styles from "./mail-extractor.module.css"
import { AdminPanelContext } from ".";
import Option from "../controller/options";
import { useNavigate } from "react-router-dom";
import { api_endpoint } from "../../config";
import Popup from "../../components/popup.module";
import InputForm from "../../components/inputform.module";
import Semaine from "../controller/semaine";
import convertISODate from "../../Utilitaire/date";
import Commande from "../controller/commande";

function MailExtractor(props) {

    const { setTitle, getToken } = useContext(AdminPanelContext);
    setTitle("Extraction des mails");

    const navigate = useNavigate();

    const [stages, setStages] = useState([]);
    const [options, setOptions] = useState([]);
    const [semaines, setSemaines] = useState([]);

    const [mailingList, setMailingList] = useState({});
    const [mailingListClean, setMailingListClean] = useState([]);
    const [caseClicked, setCaseClicked] = useState([]);
    const [copied, setCopied] = useState(false);

    const getMailingList = (ml) => {
        const result = [];
        for(const i of Object.values(ml)) {
            result.push(...i);
        }
        return [...new Set(result)];
    }


    useEffect(() => {
        Stage.getAllStages().then((stg) => {
            setStages(stg);
        });

        Option.getAllOptions().then((op) => {
            setOptions(op);
        });

        Semaine.getWeeks().then((sem) => {
            setSemaines(sem);
        });

    }, []);

    const handleClickOnWeek = (numsemaine) => {
        // simulate click on case of all stage of this week
        let timer = 100;
        for(const stg of stages) {
                handleClickOnCase(stg.idstage, numsemaine);

        }

    }


    const handleClickOnCase = async (idstage, numsemaine) => {

        const key = idstage + ";" + numsemaine;
        const ml = mailingList;
        if(ml[key]) {
            delete ml[key];
        }
        else {
            ml[key] = await Commande.extractMailFromStageAndWeek(getToken(), idstage, numsemaine);
        }

        setCopied(false);
        setMailingList(ml);
        setMailingListClean(getMailingList(mailingList));
    }
    


    return (<>
        <div className={styles.root}>
            <h1>Extraction des mails</h1>
            
            <table className={styles.table}>
                <tr>
                    <th></th>
                    { stages.map((stg) => {
                        return (<th>{ stg.libelle }</th>);
                    }) }
                </tr>

                { semaines.map(function (sem) {
                    return (
                        <tr>
                            <td onClick={() => handleClickOnWeek(sem.numsemaine)} className={styles.date}>{ convertISODate(sem.datedebut).toUpperCase() }</td>
                            { stages.map((stg) => {
                                return (<td style={{ backgroundColor: (mailingList[stg.idstage + ";" + sem.numsemaine] ? "#1e7898" : "#FFFFFF00") }} onClick={() => handleClickOnCase(stg.idstage, sem.numsemaine)}></td>);
                            }) }
                        </tr>
                    );
                }) }

            </table>


            <table className={styles.mailList}>
                <tr>
                    <th>
                        Liste des adresses emails ({ mailingListClean.length })
                        <a style={{color: "white"}} onClick={() => {
                            // Copier dans le presse papier
                            navigator.clipboard.writeText(mailingListClean.join(","));
                            setCopied(true);
                        }}>{ (!copied) ? 'COPIER DANS LE PRESSE PAPIER' : 'COPIE EFFECTUÉE'}</a>
                    </th>
                </tr>
                { mailingListClean.length == 0 && <tr><td>Aucune adresse email. Cliquez sur les cases ci-dessus pour sélectionner des évènements et récupérer les mails associés.</td></tr> }
                { mailingListClean.map(i => <tr><td>{i}</td></tr>) }
            </table>

        </div>
        </>
    );

}

export default MailExtractor;