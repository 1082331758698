import axios from 'axios';

const api_endpoint = require('../config').api_endpoint;
//const api_endpoint = "http://10.192.62.84:3001/api";

class Option {

    static async getOptionsAvailables(idStage, week) {
        const res = await axios.get(api_endpoint + '/stages/' + idStage + '/options?weeks[]=' + week);
        return res.data;
    }


}

export default Option;