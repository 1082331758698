import React from "react";
import style from './liste-appel.module.css';
import { useNavigate } from "react-router-dom";

function ListeAppel(props) {

    const navigate = useNavigate();

    function getAge(datenaissance, datedebutstage) {
        const ageDifMs = new Date(datedebutstage).getTime() - datenaissance.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    function displayItems() {
        const items = [];

        props.items.forEach(item => {
            // .map(i => i.nom.toUpperCase() + " " + i.prenom + " (" + getAge(new Date(i.datenaissance), sem.datedebut) + " ans)")
            items.push(<div className={style.item} onClick={() => navigate('/admin/commandes/details/' + item.idcommande)}>{item.nom.toUpperCase() + " " + item.prenom + " (" + getAge(new Date(item.datenaissance), props.sem.datedebut) + " ans)"}</div>);
        });

        return items;
    }


    function setColor() {
        const nbPlacesRestantes = props.nbPlacesTotal - props.items.length;
        if(nbPlacesRestantes == 0) {
            return "#00FF0033";
        }
        else if(nbPlacesRestantes == props.nbPlacesTotal) {
            return "#FF000033";
        }
        else {
            return "#FF660033";
        }
    }

    function getRemplissage() {
        return Math.min(Math.round((props.items.length / props.nbPlacesTotal) * 100), 100);
    }

    return(<div className={style.root} style={{ backgroundColor: setColor() }}>
        <h2 className={style.libelle}>{ props.libelle }</h2>
        <span className={style.nbInfos}><div className={style.outboxBar}><div className={style.insideBar} style={{ width: getRemplissage() + "%"}}></div></div> {props.items.length}/{props.nbPlacesTotal}</span>
        { displayItems() }
    </div>);

}

export default ListeAppel;