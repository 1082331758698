import React, { useContext, useEffect, useState } from "react";
import Popup from "./popup.module";
import Semaine from "../backoffice/controller/semaine";
import convertISODate from "../Utilitaire/date";
import InputForm from "./inputform.module";
import { AdminPanelContext } from "../backoffice/screens";
import Option from "../backoffice/controller/options";

function OptionPopupSelector(props) {

    const [options, setOptions] = useState([]);
    const [optionSelected, setOptionSelected] = useState({});

    const { getToken } = useContext(AdminPanelContext);

    useEffect(function () {
        Option.getAllOptions().then(o => {
            setOptions(o);
            setOptionSelected(o[0]);
        });
    }, []);

    return (<><Popup title="Sélection d'une option" close={props.close}>
        <select style={{ width: "90%" }} value={optionSelected.idoption} onChange={(e) => {
            setOptionSelected(options.find(i => i.idoption == e.target.value));
        }}>
            {options.map(o => 
                <option value={o.idoption}>{ o.libelle } ({ o.horairedebut })</option>
            )}
        </select>
        <button type="button" className="actionButton" onClick={() => {
            props.close();
            props.onResponse(optionSelected);
        }}>SUIVANT</button>
    </Popup>
    
    </>);

}

export default OptionPopupSelector;