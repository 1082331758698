import React, { useState } from "react";
import Stages from "../components/stages.module";
import Options from "../components/options.module";
import convertISODate from "../Utilitaire/date";

import style from './recapitulatif.module.css';

function Recapitulatif(props) {

    const week = props.parameters.weeks.find(w => w.numsemaine === props.parameters.stage.numsemaine);

    return (
        <div className="root">
            <h2 className="title">Votre stage du {convertISODate(week.datedebut)} au {convertISODate(week.datefin)} :</h2>

            <div className={style.main}>
                <Stages stage={props.parameters.stage} onclick={() => {}}></Stages>
                {props.parameters.option ? (<><p className={style.plus}>+</p><Options stage={props.parameters.stage} option={props.parameters.option} onclick={() => {}} /></>) : null}
            </div>
            <p className={style.prixTotal}>TOTAL : {parseFloat(props.parameters.stage.prix) + parseFloat((props.parameters.option) ? props.parameters.option.prix : 0)}€</p>
            <button className="actionButton" onClick={() => props.next() }>Confirmer la commande</button>

        </div>
    );

}

export default Recapitulatif;