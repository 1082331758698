import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Auth {

    static async login(login, passwd) {
        const res = await axios.post(api_endpoint + '/auth/login', { login, passwd });
        return res.data;
    }

}

export default Auth;