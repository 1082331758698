import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Global } from "../App";
import InputForm from "../components/inputform.module";
import Commande from "../controller/commandes.controller";
import style from './informations.module.css';
import HeaderStep from "../ScreenPart/header-step.module";

function Informations(props) {

    const { showError } = useContext(Global);

    const [formuReponses, setFormuReponses] = useState({  
        nom: null,
        prenom: null,
        email: null,
        tel: null,
        adresse: null,
        codepostal: null,
        ville: null        
      });
    
    const [isValid, setIsValid] = useState(false);
    const navigate = useNavigate();


    let formulaireController = function(name, value) {
        formuReponses[name] = value;

        setFormuReponses(formuReponses);

        let v = true;

        // On vérifie que tout les champs soient corrects
        for(const champ in formuReponses) {
            v &= (formuReponses[champ] !== null)
        }

        setIsValid(v);
    }

    const addClient = async function() {
        try {
            let codecommande = localStorage.getItem('codecommande');
            await Commande.addClient(codecommande, formuReponses);
            localStorage.setItem('codecommande', '');
            navigate('/order/' + codecommande);
        }
        catch(err) {
            showError("Une erreur est survenue, veuillez réessayer");
        }
    }

    return (<>
        <HeaderStep title={"Informations payeur"}></HeaderStep>

        <div className="root">
            <p className="explication">Veuillez saisir les informations concernant le payeur. Les champs marqués d'un (*) sont obligatoires.</p>

            <form className={style.formu}>
                <div>
                    <InputForm type="text" libelle="Nom" name="nom" placeholder="JEAN" autoFocus={true} controller={ formulaireController } required={true}></InputForm>
                    <InputForm type="text" libelle="Prénom" name="prenom" placeholder="Pierre" controller={ formulaireController } required={true}></InputForm>
                </div>
                
                <div>
                    <InputForm type="mail" libelle="Adresse Email" placeholder="jean.pierre@ycbl.fr" name="email" controller={ formulaireController } required={true}></InputForm>
                </div>

                <div>
                    <InputForm type="phone" libelle="Numéro de téléphone" placeholder="0123456789" name="tel" controller={ formulaireController } required={true}></InputForm>
                </div>

                <div>
                    <InputForm type="adress" libelle="Adresse" placeholder="14 Place doyen goss" name="adresse" controller={ formulaireController } required={true}></InputForm>
                </div>

                <div>
                    <InputForm type="adress" libelle="Complément d'adresse" placeholder="Porte A, batiment C" name="adresse2" controller={ formulaireController }></InputForm>
                </div>

                <div>
                    <InputForm type="zipcode" libelle="Code postal" placeholder="38000" name="codepostal" controller={ formulaireController } required={ true }></InputForm>
                    <InputForm type="text" libelle="Ville" placeholder="Grenoble" name="ville" controller={ formulaireController } required={ true }></InputForm>
                </div>

                <button type="button" className="actionButton" onClick={addClient} disabled={!isValid} >SUIVANT</button>

                <p><br/></p>

            </form>


        </div>
    </>);

}

export default Informations;