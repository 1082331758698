import React, { useContext } from "react";
import InputForm from "../../components/inputform.module";
import styles from './login.module.css';
import { useState } from "react";
import Auth from "../controller/auth";
import { Global } from "../../App";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

function LoginAdmin(props) {

    const [formuReponses, setFormuReponses] = useState({
        login: null,
        password: null
    });

    const [cookies, setCookie] = useCookies(['token']);

    const { showError } = useContext(Global);

    const [isValid, setIsValid] = useState(false);
    const navigate = useNavigate();

    let formulaireController = function(name, value) {
        formuReponses[name] = value;

        setFormuReponses(formuReponses);

        let v = true;

        // On vérifie que tout les champs soient corrects
        for(const champ in formuReponses) {
            v &= (formuReponses[champ] !== null)
        }

        setIsValid(v);
    }


    const login = async function() {
        try {
            const data = await Auth.login(formuReponses.login, formuReponses.password);
            setCookie('token', data.token);
            navigate('/admin/');
        }
        catch (err) {
            showError("Erreur lors de l'authentification.");
        }
    }

    return (
        <div className={styles.root}>
            <h1>Connexion backoffice</h1>
            <div><InputForm type="text" libelle="Nom d'utilisateur" placeholder="Admin" name="login" controller={formulaireController} required={true}></InputForm></div>
            <div><InputForm type="password" libelle="Mot de passe" placeholder="*****" name="password" controller={formulaireController} required={true}></InputForm></div>
            <button onClick={ login } className="actionButton" disabled={!isValid}>Connexion</button>
        </div>
    )

}

export default LoginAdmin;