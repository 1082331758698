import React, { useContext, useEffect, useState } from "react";
import Stage from "../controller/stages";
import styles from "./editor.module.css";
import { AdminPanelContext } from ".";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import InputForm from "../../components/inputform.module";
import convertISODate from "../../Utilitaire/date";
import Popup from "../../components/popup.module";
import { UilEdit } from '@iconscout/react-unicons';
import { UilPause } from '@iconscout/react-unicons';
import { UilPlay } from '@iconscout/react-unicons';
import WeekPopupSelector from "../../components/week-popup-selector.module";
import OptionPopupSelector from "../../components/option-popup-selector.module";
import Option from "../controller/options";
import { Alert } from "@mui/material";
import { api_endpoint } from "../../config";
import Upload from "../controller/uploadService";

function OptionsEditor(props) {

    const { setTitle, getToken } = useContext(AdminPanelContext);
    setTitle("Éditeur d'option");

    const [option, setOption] = useState({});
    const [stocks, setStocks] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [showPopupStock, setShowPopupStock] = useState(false);
    const [stockPopup, setStockPopup] = useState({});
    const [newWeekPopup, setNewWeekPopup] = useState(false);
    const [isSaved, setIsSaved] = useState(true);

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        Option.getOptionById(id).then((op) => {
            setOption(op);
            testIfValid(op);
        });

        Option.getStockByWeek(id).then((st) => {
            setStocks(st);
        });

    }, []);
    

    let formulaireController = function(name, value) {
        const formuReponses = option;
        formuReponses[name] = value;

        setOption({...formuReponses});
        console.log(name, option.libelle);

        // On vérifie que tout les champs soient corrects
        testIfValid(formuReponses);
        setIsSaved(false);
    
    }

    const popupStockController = function(name, value) {
        if(name == "stock") {
            setStockPopup({...stockPopup, nbplaces: value});
        }
    }


    const testIfValid = function(formuReponses) {

        let v = true;

        for(const champ in formuReponses) {
            v &= (formuReponses[champ] !== null)
        }

        setIsValid(v);
    }

    const editStock = function(stock) {
        setStockPopup(stock);
        setShowPopupStock(true);
        setIsSaved(false);

    }


    const saveModif = async function() {
        await Option.updateOption(getToken(), option);
        await Option.setStock(getToken(), id, stocks);
        window.location.reload();
        setIsSaved(true);

    }

    const deleteSemaine = () => {
        const stocksTmp = stocks.filter(i => i.numsemaine !== stockPopup.numsemaine);

        setStocks([...stocksTmp]);
        setShowPopupStock(false);
        setIsSaved(false);

    }

    const displayStock = function(stock) {
        return (
            <tr style={(stock.forcecomplet) ? { backgroundColor: "rgba(232, 149, 144, 0.5)"} : null}>
                <td>{ convertISODate(stock.datedebut).toUpperCase() } - { convertISODate(stock.datefin).toUpperCase() } </td>
                <td>{ (stock.forcecomplet ? <UilPause /> : <UilPlay />) }</td>
                <td>{ stock.nbplaces }</td>
                <td onClick={() => editStock(stock)} className={styles.btnEdit}><UilEdit></UilEdit></td>
            </tr>
        )
    }

    const deleteOption = async () => {
        if(window.confirm("Êtes-vous sûr de vouloir supprimer cette option ?")) {
            await Option.deleteOption(getToken(), id);
            navigate('/admin/stages');
        }
    }


    const saveStock = () => {
        const stocksTmp = stocks;

        const s = stocksTmp.find(i => i.numsemaine === stockPopup.numsemaine);
        s.nbplaces = stockPopup.nbplaces;
        s.forcecomplet = stockPopup.forcecomplet;

        setStocks([...stocksTmp]);
        setShowPopupStock(false);
        setIsSaved(false);

    }

    const handleUpload = async (e) => {
        const file = e.target.files[0];

        const res = await Upload.uploadFile(getToken(), file);
        const filename = res.filename;

        setOption({...option, image: filename});
        setIsSaved(false);
        console.log("upload", res);
    }

    return (
        <div className={styles.root}>
            <div className={styles.topHead}>
                <a onClick={() => navigate('/admin/stages')}>◀︎ RETOUR LISTE STAGES / OPTIONS</a>
                <h1>{ option.libelle }</h1>
                <p className={styles.alertMsg}>{ !isSaved ? "MODIFICATIONS NON ENREGISTRÉES !" : null }</p>
            </div>
            <div className={styles.content}>
                <form className={styles.formu}>
                    <div>
                        <label className={styles.img} for="imgupload">
                            <input accept="image/png, image/jpeg, image/jpg" id="imgupload" name="imgupload" type="file" onChange={handleUpload} style={{display: "none"}}></input>
                            { option.image ? <img src={api_endpoint + "/uploads/get/" + option.image} alt="Image du stage" /> : null }
                        </label>
                    </div>
                    <div>
                        <InputForm type="text" modifIndicator={true} value={option.libelle} libelle="Nom de l'option" name="libelle" placeholder="Escalade" controller={ formulaireController } required={true}></InputForm>
                    </div>
                    <div>
                        <InputForm type="time" modifIndicator={true} value={option.horairedebut} libelle="Horaire de début" name="horairedebut" placeholder="13:00"  controller={ formulaireController } required={true}></InputForm>
                        <InputForm type="time" modifIndicator={true} value={option.horairefin} libelle="Horaire de fin" name="horairefin" placeholder="17:00" controller={ formulaireController } required={true}></InputForm>
                    </div>
                    <div>
                        <InputForm type="textarea" modifIndicator={true} value={option.description} libelle="Description de l'option" name="description" placeholder="Décrivez le contenu de l'activité ici..." controller={ formulaireController } required={true}></InputForm>
                    </div>
                </form>
                <div className={styles.stock}>
                    <table>
                        <tr>
                            <th>SEMAINE</th>
                            <th>STATUT</th>
                            <th>Nb. PLACES</th>
                            <th>EDIT.</th>
                        </tr>
                        { stocks.length === 0 ? <tr><td colSpan="4">Aucune semaine n'a été définie pour l'instant.</td></tr> : null}
                        { stocks.map(s => displayStock(s)) }
                    </table>
                    <a className={styles.addBtn} onClick={() => setNewWeekPopup(true)}>+ AJOUTER UNE SEMAINE</a>

                    
                </div>
                
            </div>

            <div className={styles.bottomBtn}>
                <button type="button" className="actionButtonNegative" onClick={deleteOption} >SUPPRIMER L'OPTION</button>
                <button type="button" className="actionButton" onClick={saveModif} >ENREGISTER LES MODIFICATIONS</button>
            </div>
            { showPopupStock ? <Popup title={"Gestion stock"} close={() => setShowPopupStock(false)}> 
                <h1>{ convertISODate(stockPopup.datedebut).toUpperCase() } - { convertISODate(stockPopup.datefin).toUpperCase() }</h1>
                <div className={styles.pauseBtn}>
                    <span>EN PAUSE</span>
                    <input type="checkbox" checked={stockPopup.forcecomplet} onChange={(e) => {
                        setStockPopup({...stockPopup, forcecomplet: e.target.checked})
                    }}></input>
                </div>
                <InputForm type="number" min={0} value={stockPopup.nbplaces} libelle="Nombre de places disponibles" name="stock" placeholder="14" required={true} controller={popupStockController} disabled={stockPopup.forcecomplet}></InputForm>
                <a onClick={deleteSemaine}>SUPPRIMER LA SEMAINE</a>
                <button type="button" className="actionButton" onClick={saveStock}>ENREGISTRER</button>
            </Popup> : null }


            {
                newWeekPopup ? <WeekPopupSelector close={() => setNewWeekPopup(false) } onResponse={
                    (rep) => {
                        setStocks([...stocks, {
                            numsemaine: rep.numsemaine,
                            datedebut: rep.datedebut,
                            datefin: rep.datefin,
                            forcecomplet: false,
                            nbplaces: 0
                        }])
                    }
                } /> : null
            }
            

        </div>
    )

}

export default OptionsEditor;