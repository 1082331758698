import React, { useEffect, useState, useContext } from "react";
import InputForm from "../components/inputform.module";
import style from './informations.module.css';

import { FormContext } from "./creator-form.module";

function StagiaireInfos(props) {

    const [formuReponses, setFormuReponses] = useState({  
        nom: null,
        prenom: null,
        datenaissance: null,
        autorisationParentale: false,
        droitImage: false,
        rentrerSeul: false,
        nom_resp: null,
        qualite_resp: null,
        nom_accompagnant: null,
        tel_accompagnant: null 
      });
    
    const [showAcc, setShowAcc] = useState(true);
    
    const [isValid, setIsValid] = useState(false);

    const { parameters, nextStep } = useContext(FormContext);

    console.log(parameters);


    let formulaireController = function(name, value) {
        formuReponses[name] = value;

        setFormuReponses(formuReponses);

        if(name === "rentrerSeul") {
            setShowAcc(!value);
        }

        let v = true;

        // On vérifie que tout les champs soient corrects
        if(formuReponses.nom === null || formuReponses.nom === "") {
            v = false;
        }
        if(formuReponses.prenom === null || formuReponses.prenom === "") {
            v = false;
        }
        if(formuReponses.datenaissance === null || formuReponses.datenaissance === "") {
            v = false;
        }
        if(parameters.stage.agemax < 18) {
            if(formuReponses.autorisationParentale === null || formuReponses.autorisationParentale === false) {
                v = false;
            }
            if(formuReponses.nom_resp === null || formuReponses.nom_resp === false) {
                v = false;
            }
            if(!formuReponses.rentrerSeul) {
                if(formuReponses.nom_accompagnant === null || formuReponses.nom_accompagnant === "") {
                    v = false;
                }
                if(formuReponses.tel_accompagnant === null || formuReponses.tel_accompagnant === "") {
                    v = false;
                }
            }
        }

        setIsValid(v);
    }

    return (
        <div className="root">
            <p className="explication">Veuillez saisir les informations concernant le stagiaire. Les champs marqués d'un (*) sont obligatoires.</p>

            <form className={style.formu}>
                <div>
                    <InputForm type="text" libelle="Nom" name="nom" placeholder="JEAN" autoFocus={true} controller={ formulaireController } required={true}></InputForm>
                    <InputForm type="text" libelle="Prénom" name="prenom" placeholder="Pierre" controller={ formulaireController } required={true}></InputForm>
                </div>
                <div>
                    <InputForm type="date" agemin={parameters.stage.agemin} agemax={parameters.stage.agemax} when={parameters.stage.datedebut} libelle="Date de naissance" name="datenaissance" controller={ formulaireController } required={true}></InputForm>
                </div>

                {
                    parameters.stage.agemax < 18 ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                            <h2>AUTORISATION PARENTALE</h2>
                            <p style={{ maxWidth: '90vw', width: 700, textAlign: "justify"}}>
                            Autorise le stagiaire ici nommé, à pratiquer les activités nautiques auxquelles il (elle) est inscrit(e) au Club & École de voile YCBL.
                            Et je déclare que l’enfant est en bonne santé et possède les aptitudes requises à une pratique nautique. 
                            Soit par sa réussite au Pass’Nautique (anciennement Test d’aisance aquatique) notamment validé en primaire.
                            Soit en possédant l' Attestation du Savoir Nager en Sécurité (ASNS, passé en 6ème). 
                            En l’absence de ces tests, cette autorisation parentale appuie tout engagement du responsable légal quant au fait que l’enfant ne panique pas dans l’eau. Sachant que l’enfant peut être soumis sur place par un titulaire qualifié, à un test correspondant aux conditions de sa pratique, avec brassière s’il y a lieu.
                            De plus, et en cas d’urgence, j’autorise son acheminement dans un établissement hospitalier où toute intervention chirurgicale pourra être pratiquée en cas de nécessité.

                            Assurance : Tous les participants sont couverts en risque individuel et responsabilité civile uniquement dans le cadre des activités organisées par le Yacht Club Chambéry Le Bourget du Lac. Notre responsabilité ne s’étend pas au-delà des horaires d’encadrement.
                            </p>

                            <div style={{ margin: "15px 0px"}}>
                                <div style={{ display: "flex", alignItems: "center"}}>
                                    <input type="checkbox" id="autorisationParentale" name="autorisationParentale" onChange={(e) => {
                                        formulaireController("autorisationParentale", e.target.checked);
                                    }}></input>
                                    <label for="autorisationParentale">ACCEPTER L'AUTORISATION PARENTALE</label>
                                </div>
                                <div style={{ display: "flex", alignItems: "center"}}>
                                    <input type="checkbox" id="droitImage" name="droitImage" onChange={(e) => {
                                        formulaireController("droitImage", e.target.checked);
                                    }}></input>
                                    <label for="droitImage">CÉDER LE DROIT À L'IMAGE</label>
                                </div>
                                <div style={{ display: "flex", alignItems: "center"}}>
                                    <input type="checkbox" id="rentrerSeul" name="rentrerSeul" onChange={(e) => {
                                        formulaireController("rentrerSeul", e.target.checked);
                                    }}></input>
                                    <label for="rentrerSeul">AUTORISE LE STAGIAIRE MINEUR A RENTRER SEUL</label>
                                </div>
                            </div>

                            <div style={{ display: "flex", alignItems: "baseline"}}>
                            <span className={style.span}>
                                <label className={style.label}>Qualité du responsable (*)</label>
                                <select className="selectInput" style={{ flexGrow: 1, maxHeight: 55}} name="qualite_resp" onChange={(e) => formulaireController("qualite_resp", e.target.value)}>
                                    <option value="mere">Mère</option>
                                    <option value="pere">Père</option>
                                    <option value="tuteur">Tuteur</option>
                                </select>
                            </span>
                                <InputForm type="text" libelle="Nom et Prénom responsable" name="nom_resp" placeholder="" autoFocus={false} controller={ formulaireController } required={true}></InputForm>
                            </div>
                            {
                                showAcc ?
                            
                            <div style={{ display: "flex"}}>
                                <InputForm type="text" libelle="Nom et Prénom personne venant récupérer le stagiaire" name="nom_accompagnant" placeholder="" autoFocus={false} controller={ formulaireController } required={true}></InputForm>
                                <InputForm type="phone" libelle="Numéro de téléphone" name="tel_accompagnant" placeholder="" autoFocus={false} controller={ formulaireController } required={true}></InputForm>

                            </div> : null }
                        </div>
                    : null
                }

                <button type="button" className="actionButton" onClick={ () => nextStep(formuReponses) } disabled={!isValid} >SUIVANT</button>

                <p><br/></p>

            </form>


        </div>
    );

}

export default StagiaireInfos;