import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Global } from "../App";
import Commande from "../controller/commandes.controller";
import Reservation from "../controller/reservations.controller";
import PaiementModule from "../ScreenPart/paiement.module";
import styles from './reservation-index.module.css';
import QRCode from "react-qr-code";
import HeaderStep from "../ScreenPart/header-step.module";

function ReservationIndex(props) {

    const { ref } = useParams(); 

    const navigate = useNavigate();

    const [commandeDatas, setCommandeDatas] = useState({});
    const [ paiementsEnAttente, setPaiementsEnAttente ] = useState(0);
    const [ montantPaye, setMontantPaye ] = useState(0);
    const [ listPaiements, setListPaiements ] = useState([]);

    let status_code = {
        "WAITING_PAY": "ATTENTE DE PAIEMENT",
        "CONFIRMED": "CONFIRMÉE"
    }

    let paiement_code = {
        "CARD": "CARTE BANCAIRE",
        "CARD_ONLINE": "CARTE BANCAIRE",
        "CASH": "ESPECES",
        "TRANSFER": "VIREMENT BANCAIRE",
        "CHEQUE": "CHEQUES",
        "CHEQUEHOLY": "CHEQUE VACANCES",
        "REFUND": "REMBOURSEMENT",
        "REDUC_ADH": "REDUCTION ADHERENT",
        "REDUC_PERSO": "REDUCTION PERSONNALISEE",
        "BON_CADEAU": "BON CADEAU",
    }

    const { showError } = useContext(Global);

    const refresh = function () {
        Commande.getCommande(ref)
            .then(data => {
                console.log('infos', data);
                setCommandeDatas(data)
            })
            .catch((err) => {
                console.log(err);
                if(err.response.status == 404) {
                    navigate("/notfound");
                }
                else {
                    showError("Une erreur est survenue, veuillez réessayer.");
                }
            });
        
        Commande.getAllPaiements(ref)
            .then(paiements => {
                setListPaiements(paiements.filter(p => (p.statut === true || !p.moyenpaiement.includes('CARD'))));
                let pAtt = 0;

                paiements.forEach(p => {
                    if(p.statut == false && (!p.moyenpaiement.includes('CARD'))) {
                        pAtt += Number.parseFloat(p.prix);
                    }
                });

                setPaiementsEnAttente(pAtt);
            });
    }

    useEffect(() => {

        refresh();

    }, []);


    return (<>
        <HeaderStep title={"Votre commande"}></HeaderStep>

        <div>
            <p className={styles.infos}>REF. COMMANDE : <span className={styles.value}>{ commandeDatas.refcommande }</span></p>
            <p className={styles.infos}>STATUS : <span className={styles.value}>{ status_code[commandeDatas.status] }</span></p>
            <p className={styles.infos} style={{ marginBottom: 10 }}>TOTAL : <span className={styles.value}>{commandeDatas.prixtotal} € </span></p>
            {
                listPaiements.map((p) => {
                    return (
                        <p style={{color: (p.statut) ? "GREEN" : "DARKORANGE"}} className={styles.infos}>{paiement_code[p.moyenpaiement]} : <span className={styles.value}>{p.prix} €</span></p>
                    )
                })
            }
            <p className={styles.infos} style={{ marginTop: 10 }}>{(commandeDatas.resteapayer - paiementsEnAttente < 0) ? "REMBOURSEMENT EN ATTENTE" : "RESTE A PAYER"} : <span className={styles.value}>{Math.abs(commandeDatas.resteapayer - paiementsEnAttente)} €</span></p>

            { (!commandeDatas.resteapayer || (commandeDatas.resteapayer - paiementsEnAttente) > 0) ? <PaiementModule code={ref} prix={commandeDatas.resteapayer - paiementsEnAttente} refresh={refresh}></PaiementModule> : 
                (paiementsEnAttente == 0 && commandeDatas.status == "CONFIRMED") ? 
                <div className={styles.confirmBox}>
                    <h1 class={styles.title}>Commande confirmée !</h1> 
                    <QRCode style={{ margin: 10, padding: 10, border: "2px solid black", borderRadius: 10, backgroundColor: "white" }} size={150} value={"https://devistorm.fr/view/" + ref}></QRCode>
                    <p className={styles.txt}>Votre commande est maintenant confirmée, vous allez recevoir un mail de confirmation avec un récapitulatif de votre commande. Vous pouvez utiliser ce QRCode lorsque vous vous présenterez le premier jour du stage.</p>

                    <button class="actionButton" onClick={() => window.open('/api/paiements/facture/' + ref, '_blank')}>Télécharger la facture</button>
                </div> : null
            }

        </div>
    </>);

}

export default ReservationIndex;