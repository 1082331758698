import React, { useState } from "react";
import style from "./weeks.module.css";
import convertISODate from "../Utilitaire/date";



function Weeks(props) {

    const [select, setSelect] = useState(false);

    const click = function () {
        select ? props.onDeselect(props.week) : props.onSelect(props.week);
        setSelect(!select);
        
    }

    return (
        <div onClick={ click } className={style.root} style={{backgroundColor: select ? "#2197B1" : "#2197B111", color: select ? "white" : "#2197B1"}}>
            <p className={style.title}>{convertISODate(props.week.datedebut).toUpperCase()} - {convertISODate(props.week.datefin).toUpperCase()}</p>
        </div>
    );

}

export default Weeks;