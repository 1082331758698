import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Global } from "../App";
import HeaderStep from "../ScreenPart/header-step.module";

function Home(props) {

    const navigate = useNavigate();

    const createNewCommand = function() {
        localStorage.clear();
        navigate('/creator');
    }

    return (
        <>
            <HeaderStep title={"Accueil"}></HeaderStep>

            <div class="root">
                <h1>Commencez à réserver un stage dès maintenant ! En quelques minutes.</h1>
                <p>Trouvez le stage qui vous correspond, en fonction de vos disponibilités, de votre âge et de votre niveau.</p>
                <p>Paiement en ligne par carte bancaire <b>sécurisé.</b></p>
                <p><br></br></p>
                <button class="actionButton" onClick={() => createNewCommand()}>Commencer !</button>
            </div>
        </>
    );
}

export default Home;