import React, { useContext } from "react";
import Stage from "../controller/stages.controller";
import { useEffect, useState } from 'react';
import Stages from "../components/stages.module";

import styles from "./list-stages.module.css";
import convertISODate from "../Utilitaire/date";
import { Global } from "../App";

import ReactLoading from 'react-loading';

function ListStages(props) {

    const [stages, setStages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { showError } = useContext(Global);

    useEffect(() => {
        Stage.getAllStagesAvailablesWithWeekAndAge(props.week.numsemaine, props.age).then((res) => {
            setStages(res);
            setIsLoading(false);
        })
    }, []);

    let listStages = stages.map((s) => 
        <Stages stage={s} onclick={props.onchoice}></Stages>
    );

    if(isLoading) {
        listStages = (<ReactLoading type={"balls"} color={"#2197B1"} height={50} width={50} />);
    }
    // on affiche une erreur s'il n'y a pas de stage disponible
    else if(listStages.length === 0) {
        listStages = (<p className="error">Il n'y a aucun stage disponible pour cette semaine et pour cet âge. Rééssayez en changeant les semaines sélectionnées.</p>)
    }


    return (
        <div className={styles.root}>
            <h2 className="title"><b>{convertISODate(props.week.datedebut).toUpperCase()}</b> - <b>{convertISODate(props.week.datefin).toUpperCase()}</b></h2>

            <div className={styles.list}>
                {listStages}
            </div>
        </div>
    );
}

export default ListStages;