import { textAlign } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminPanelContext } from ".";
import Client from "../controller/client";
import Commande from "../controller/commande";
import styles from './commande-details.module.css';
import convertISODate from "../../Utilitaire/date";
import InputForm from "../../components/inputform.module";
import Popup from "../../components/popup.module";
import { useNavigate } from "react-router-dom";
import { UilEdit } from '@iconscout/react-unicons';
import Semaine from "../controller/semaine";
import Stage from "../controller/stages";
import Option from "../controller/options";
import Reservation from "../controller/reservations";
import { UilCheckCircle } from '@iconscout/react-unicons';
import { UilPrint } from '@iconscout/react-unicons';
import { UilEnvelope } from '@iconscout/react-unicons';
import { UilTimesCircle } from '@iconscout/react-unicons'
import { api_endpoint } from "../../config";
import { UilTimes } from '@iconscout/react-unicons'
import { UilTrashAlt } from '@iconscout/react-unicons'
import { UilPlus } from '@iconscout/react-unicons';
import { UilEnvelopeCheck } from '@iconscout/react-unicons'
import { UilEnvelopeTimes } from '@iconscout/react-unicons'

function CommandeDetails(props) {

    let status_code = {
        "WAITING_PAY": "🟠 ATTENTE DE PAIEMENT",
        "CONFIRMED": "🟢 CONFIRMÉE"
    }

    let paiement_code = {
        "CARD": "CARTE BANCAIRE (SUR PLACE)",
        "CARD_ONLINE": "CARTE BANCAIRE (EN LIGNE)",
        "CASH": "ESPECES",
        "TRANSFER": "VIREMENT BANCAIRE",
        "CHEQUE": "CHEQUES",
        "CHEQUEHOLY": "CHEQUE VACANCES",
        "REFUND": "REMBOURSEMENT",
        "REDUC_ADH": "REDUCTION ADHERENT",
        "REDUC_PERSO": "REDUCTION PERSONNALISEE",
        "BON_CADEAU": "BON CADEAU",
    }

    const { setTitle, getToken, year } = useContext(AdminPanelContext);
    setTitle("Détails commande")

    const { id } = useParams();

    const [details, setDetails] = useState({});
    const [client, setClient] = useState({});
    const [reservations, setReservations] = useState([]);
    const [paiements, setPaiements] = useState([]);
    const [semaines, setSemaines] = useState([]);
    const [stages, setStages] = useState([]);
    const [options, setOptions] = useState([]);
    const [messagesPopup, setMessagePopup] = useState([]);
    const [updatedPrice, setUpdatedPrice] = useState(0);
    const [showPopupClient, setShowPopupClient] = useState(false);
    const [editedClient, setEditedClient] = useState({});

    const [confirmPaiementPopup, setConfirmPaiementPopup] = useState(false);
    const [paiementToConfirm, setPaiementToConfirm] = useState({});

    const [cancelPaiementPopup, setCancelPaiementPopup] = useState(false);
    const [paiementToCancel, setPaiementToCancel] = useState({});


    const [showPopupPaiement, setShowPopupPaiement] = useState(false);
    const [showPopupReservation, setShowPopupReservation] = useState(false);
    const [reservationPopup, setReservationPopup] = useState({});
    const [editPricePopup, setEditPricePopup] = useState(false);
    const [alreadyRelance, setAlreadyRelance] = useState(false);

    const [isValid, setIsValid] = useState(false);

    const navigate = useNavigate();

    function getAge(datenaissance, datedebutstage) {
        const ageDifMs = new Date(datedebutstage).getTime() - datenaissance.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const confirmPaiement = (d) => {
        setConfirmPaiementPopup(true);
        setPaiementToConfirm(d);
    }

    const cancelPaiement = (d) => {
        setCancelPaiementPopup(true);
        setPaiementToCancel(d);
    }


    useEffect(() => {
        const asf = async function() {
            const res = await Commande.getCommandeById(getToken(), id);

            if(new Date(res.datecommande).getFullYear() != year) {
                navigate('/admin/commandes');
            }

            const res2 = await Commande.getReservations(res.codecommande);
            const res3 =  await Commande.getPaiements(res.codecommande);
            const weeks = await Semaine.getWeeks(year);
            const stg = await Stage.getAllStages();
            const opt = await Option.getAllOptions();

            setOptions(opt);
            setStages(stg);
            setSemaines(weeks);
            setDetails(res);
            setReservations(res2);
            setPaiements(res3.filter(p => p.moyenpaiement != 'CARD_ONLINE' || p.statut).sort((a, b) => (!a.statut && b.statut) ? 1 : -1));
            console.log(res2);
        }
        asf();
    }, [year]);

    useEffect(() => {
        if(details.idclient)
            Client.getClientById(getToken(), details.idclient).then(res => setClient(res));
    }, [details]);

    function getAge(datenaissance, datedebutstage) {
        const ageDifMs = new Date(datedebutstage).getTime() - datenaissance.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    let listResa = reservations.map(r => 
        /*<tr>
            <td>{r.nom.toUpperCase() + " " + r.prenom} ({new Date(r.datenaissance).toLocaleDateString()})</td>
            <td>{convertISODate(r.datedebut).toUpperCase()} - {convertISODate(r.datefin).toUpperCase()}</td>
            <td>{r.libelle_stage}</td>
            <td>{r.libelle_option}</td>
            <td style={{cursor: "pointer"}}><UilEdit onClick={() => editResa(r)}></UilEdit></td>
        </tr>*/

        <div className={styles.resaBox} onClick={() => editResa(r)}>
            <img className={styles.resaImg} src={api_endpoint + "/uploads/get/" + r.image_stage} />
            <div className={styles.resaInfos}>
                <p className={styles.resaTitle}>{(r.libelle_stage + (r.libelle_option ? " + " + r.libelle_option : "")).toUpperCase()}</p>
                <p className={styles.resaDate}>{convertISODate(r.datedebut).toUpperCase()} - {convertISODate(r.datefin).toUpperCase()}</p>
                <div className={styles.resaStagiaire}>
                    <p><span className={styles.stagiaireName}>{r.nom.toUpperCase() + " " + r.prenom}</span>{" - " + getAge(new Date(r.datenaissance), r.datedebut) + " ans"} ({new Date(r.datenaissance).toLocaleDateString()})</p>
                    { r.nom_resp ? 
                    <div className={styles.autorisationMineur}>
                        <div className={styles.autorisationField}>
                            <p>Nom du responsable légal</p>
                            <p>{r.nom_resp.toUpperCase()}</p>
                        </div>
                        <div className={styles.autorisationField}>
                            <p>Droit à l'image</p>
                            <p>{(r.droitimage) ? "OUI" : "NON"}</p>
                        </div>
                        <div className={styles.autorisationField}>
                            <p>Peut rentrer seul</p>
                            <p>{(r.rentrerseul) ? "OUI" : "NON"}</p>
                        </div>
                        {
                            (!r.rentrerseul && r.nom_accompagnant) ? <div className={styles.autorisationField}>
                                <p>Personne autorisée à récupérer</p>
                                <p>{r.nom_accompagnant.toUpperCase()} (Tél: {r.tel_accompagnant})</p>
                            </div> : null
                        }
                    </div> : null }
                </div>
            </div>
        </div>
        
    );

    const editResa = async (r) => {
        // on va récupérer la liste de stage avec les disponibilitées associées à la semaine de base
        const st = await Stage.getAllStagesWithDispo(r.numsemaine);
        const op = await Option.getAllOptionsWithDispo(r.numsemaine);

        setStages(st);
        setOptions(op);
        setReservationPopup(r);
        setShowPopupReservation(true);
    }

    let listPay = paiements.map(p => 
        <tr>
            <td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}>{p.statut ? "🟢" : "🟠"}</td>
            <td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}>{p.refpaiement}</td>
            <td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}>{(new Date(p.date).toLocaleString()).toUpperCase()}</td>
            <td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}>{paiement_code[p.moyenpaiement]}</td>
            <td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}>{ Math.abs(p.prix) }€</td>
            { p.statut ? <>
                <td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}></td><td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}><a style={{ color: 'darkred'}} onClick={() => cancelPaiement(p)}><UilTimes /> &nbsp;ANNULER</a></td> </>:
            <><td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}><a  style={{ color: 'darkgreen'}} onClick={() => confirmPaiement(p)}><UilEnvelopeCheck /> &nbsp;CONFIRMER</a></td>
            <td style={{ backgroundColor: (p.statut ? "initial" : "#ffc17555")}}><a style={{ color: 'darkred'}} onClick={() => cancelPaiement(p)}><UilEnvelopeTimes /> &nbsp;ANNULER</a></td></> }
        </tr>
        
    );

    const addPaiement = async () => {
        const moyen = document.querySelector('#moyenpaiement').value;
        let prix = document.querySelector('#prix').value;

        if(moyen == "REFUND") {
            prix = -Math.abs(prix);
        }

        setShowPopupPaiement(false);

        
        const i = await Commande.addPaiement(getToken(), details.codecommande, moyen, prix);

        
        const res = await Commande.getCommandeById(getToken(), id);
        const res3 =  await Commande.getPaiements(res.codecommande);

        setDetails(res);
        setPaiements(res3);


    }

    const deleteCommande = async () => {
        if(window.confirm("Voulez-vous vraiment supprimer cette commande ?")) {
            await Commande.deleteCommande(getToken(), id);
            
            navigate('/admin/commandes');
        }
    }

    const reservationController = async (name, value) => {
        const tmp = reservationPopup;
        tmp[name] = value;
        setReservationPopup({...tmp});
    }

    const clientController = async (name, value) => {
        const tmp = editedClient;
        tmp[name] = value;
        setEditedClient({...tmp});
    }

    const updateReservation = async () => {
        await Reservation.updateReservation(getToken(), reservationPopup.idreservation, reservationPopup);
        const res = await Commande.getReservations(details.codecommande);
        setReservations(res);
        setShowPopupReservation(false);
    }

    const updateClient = async () => {
        await Client.updateClient(getToken(), client.idclient, editedClient);
        const res = await Client.getClientById(getToken(), client.idclient);
        setClient(res);
        setShowPopupClient(false);
    }

    const refreshPaiements = async () => {
        const res = await Commande.getCommandeById(getToken(), id);
        const res3 =  await Commande.getPaiements(res.codecommande);
        setDetails(res);
        setPaiements(res3.filter(p => p.moyenpaiement != 'CARD_ONLINE' || p.statut).sort((a, b) => (!a.statut && b.statut) ? 1 : -1));
    }

    const verifyResaPopup = () => {
        const messages = [];

        const st = stages.find(i => i.idstage == reservationPopup.idstage);
        if(st) {
            if(st.nbplaces == null) {
                messages.push("Le stage \"" + st.libelle + "\" n'est pas disponible.");
            }
            else if(st.nbplaces <= 0) {
                messages.push("Le stage \"" + st.libelle + "\" est complet.");
            }

            if(getAge(new Date(reservationPopup.datenaissance), reservationPopup.datedebut) < st.agemin || getAge(new Date(reservationPopup.datenaissance), reservationPopup.datedebut) > st.agemax) {
                messages.push("Le stage \"" + st.libelle + "\" n'est normalement pas proposé à cet âge.");
            }
        }

        const op = options.find(i => i.idoption == reservationPopup.idoption);
        if(op) {
            if(op.nbplaces == null) {
                messages.push("L'option \"" + op.libelle + "\" n'est pas disponible.");
            }
            else if(op.nbplaces <= 0) {
                messages.push("L'option \"" + op.libelle + "\" est complet.");
            }
            if(getAge(new Date(reservationPopup.datenaissance), reservationPopup.datedebut) < op.agemin || getAge(new Date(reservationPopup.datenaissance), reservationPopup.datedebut) > op.agemax) {
                messages.push("L'option \"" + op.libelle + "\" n'est normalement pas proposé à cet âge.");
            }
        }
        setMessagePopup(messages);
    }

    return (<>
        <div className={styles.root}>
            <h1>Détail de la commande</h1>

            { details.traitee ? <div style={{ color: "green", fontWeight: "bold"}}>COMMANDE TRAITÉE</div> : <div style={{ color: "orange", fontWeight: "bold"}}>COMMANDE NON TRAITÉE</div> }

            <div className={styles.toolBox}>
                <a onClick={() => Commande.markAsTraitee(getToken(), details.codecommande).then(res => {
                    setDetails({ ...details, traitee: res.statut })
                }) }>{ !details.traitee ? <><UilCheckCircle /> MARQUER COMME TRAITÉE</> : <><UilTimesCircle /> MARQUER COMME NON TRAITÉE</> }</a>
                <a onClick={() => window.print()}><UilPrint />  IMPRIMER</a>
                
                { details.status != "CONFIRMED" ?
                <a disabled={alreadyRelance} style={{ color: (alreadyRelance ? "green" : null)}} onClick={async () => {
                    if(!alreadyRelance) {
                        setAlreadyRelance(true);
                        await Commande.relanceCommande(getToken(), client.email, details.codecommande);
                        setDetails({ ...details, datelastrelance: new Date().toISOString() });

                    }
                }}>{!alreadyRelance ? <><UilEnvelope />  ENVOYER MAIL DE RELANCE</> : <><UilEnvelope />  MAIL ENVOYÉ</> }</a> : null }
                
                <a onClick={deleteCommande} style={{ color: 'darkred'}}><UilTrashAlt /> SUPPRIMER LA COMMANDE</a>

            </div>

            <div className={styles.listInfos}>
                <div className={styles.infosBox}>
                    <h2>Informations commandes</h2>
                    <p className={styles.infos}>Réf. commande : <span className={styles.value}>{ details.refcommande }</span></p>
                    <p className={styles.infos}>Status : <span className={styles.value}>{status_code[details.status]}</span></p>
                    <p className={styles.infos}>Date de commande : <span className={styles.value}>{new Date(details.datecommande).toLocaleString()}</span></p>
                    <p className={styles.infos}>Prix total : <span className={styles.value}>{ details.prixtotal }€</span></p>
                    <p className={styles.infos} style={{color: details.resteapayer < 0 ? "red" : "black" }}>{(details.resteapayer >= 0) ? "Reste à payer" : "Reste à rembourser"} : <span className={styles.value}>{ Math.abs(details.resteapayer) }€</span></p>
                    { details.status != "CONFIRMED" ?
                        <p className={styles.infos}>Dernière relance : <span className={styles.value}>{ details.datelastrelance ?  convertISODate(details.datelastrelance).toUpperCase() : "JAMAIS" }</span></p> : null }
                    <a style={{marginTop: 20}} onClick={() => {
                        setEditPricePopup(true);
                        setUpdatedPrice(details.prixtotal);
                    }}>MODIFIER LE PRIX DE LA COMMANDE</a>
                </div>

                <div className={styles.infosBox}>
                    <h2>Informations clients</h2>
                    <p className={styles.infos}>Nom : <span className={styles.value}>{ client.nom }</span></p>
                    <p className={styles.infos}>Prénom : <span className={styles.value}>{ client.prenom }</span></p>
                    <p className={styles.infos}>Email : <span className={styles.value}>{ client.email }</span></p>
                    <p className={styles.infos}>Tel : <span className={styles.value}>{ client.tel }</span></p>
                    <p className={styles.infos}>Adresse : <span className={styles.value} style={{textAlign: 'right'}}>{ client.adresse } <br /> {client.codepostal} {client.ville} </span></p>
                    <a onClick={() => {
                        setShowPopupClient(true);
                        setEditedClient({...client});
                    }}>MODIFIER INFOS CLIENT</a>
                </div>
            </div>

            <h2 className={styles.subtitle}>Liste des réservations</h2>

            <div className={styles.listResaBox}>
                { listResa }
            </div>


            <h2 className={styles.subtitle}>Liste des paiements</h2>

            <table>
                <tr>
                    <th>Statut</th>
                    <th>Référence paiement</th>
                    <th>Date</th>
                    <th>Moyen de paiement</th>
                    <th>Montant</th>
                    <th></th>
                    <th></th>
                </tr>
                { listPay }
                { paiements.length == 0 ? <tr><td colSpan="4">Aucun paiement validé sur cette commande.</td></tr> : null}
            </table>

            <a onClick={() => setShowPopupPaiement(true)} className={styles.addBtn}>+ AJOUTER UN PAIEMENT</a>
            
        </div>

        { showPopupPaiement ? <Popup title="Ajout moyen de paiement" close={() => setShowPopupPaiement(false)}> 
            <div className={styles.payBox}>
                <div>
                <select name="moyenpaiement" id="moyenpaiement">
                    {
                        Object.keys(paiement_code).filter(i => i != 'CARD_ONLINE').map(k => {
                            return <option value={k}>{paiement_code[k]}</option>
                        })
                    }
                </select>
                <InputForm id="prix" type="number" libelle="Montant (€)" name="prix" controller={(name, value) => { setIsValid(value > 0) }}></InputForm>
                </div>
                <button class="actionButton" onClick={addPaiement} disabled={!isValid}>Ajouter un paiement</button>
            </div> 
        </Popup> : null }

        { showPopupClient ? <Popup title={"Modification client"} close={() => setShowPopupClient(false)}> 
            <form className={styles.formu}>
                <div>
                    <InputForm type="text" value={editedClient.nom} libelle="Nom" name="nom" placeholder="Dupont" required={true} controller={clientController} ></InputForm>
                    <InputForm type="text" value={editedClient.prenom} libelle="Prénom" name="prenom" placeholder="Pierre" required={true} controller={clientController} ></InputForm>
                </div>
                <div>
                    <InputForm type="mail" value={editedClient.email} libelle="Adresse email" name="email" placeholder="jean@gmail.com" required={true} controller={clientController} ></InputForm>
                </div>
                <div>
                    <InputForm type="phone" value={editedClient.tel} libelle="Numéro téléphone" name="tel" placeholder="0123456789" required={true} controller={clientController} ></InputForm>
                </div>
                <div>
                    <InputForm type="text" value={editedClient.adresse} libelle="Adresse" name="adresse" placeholder="3 ROUTE DU MOULIN" required={true} controller={clientController} ></InputForm>
                </div>
                <div>
                    <InputForm type="zipcode" value={editedClient.codepostal} libelle="Code postal" name="codepostal" placeholder="38000" required={true} controller={clientController} ></InputForm>
                    <InputForm type="text" value={editedClient.ville} libelle="Ville" name="ville" placeholder="Grenoble" required={true} controller={clientController} ></InputForm>
                </div>
                    
                <button type="button" className="actionButton" onClick={updateClient}>ENREGISTRER</button>
            </form>
            </Popup> : null }

        
            { showPopupReservation ? <Popup title={"Modification réservation"} close={() => setShowPopupReservation(false)}> 
            <form className={styles.formu}>
                <div>
                    <InputForm type="text" value={reservationPopup.nom} libelle="Nom du stagiaire" name="nom" placeholder="Dupont" required={true} controller={reservationController} ></InputForm>
                    <InputForm type="text" value={reservationPopup.prenom} libelle="Prénom du stagiaire" name="prenom" placeholder="Pierre" required={true} controller={reservationController} ></InputForm>
                </div>
                <div>
                    <InputForm type="date" value={new Date(reservationPopup.datenaissance).toISOString().split('T')[0]} libelle="Date de naissance" name="datenaissance" placeholder="03/11/2023" required={true} controller={reservationController} ></InputForm>
                </div>
                    <select style={{width: "inherit"}} value={reservationPopup.numsemaine} onChange={async (e) => {

                        reservationController("numsemaine", e.target.value);

                        const st = await Stage.getAllStagesWithDispo(e.target.value);
                        const op = await Option.getAllOptionsWithDispo(e.target.value);

                        setStages(st);
                        setOptions(op);

                        verifyResaPopup();

                    }}>

                        {semaines.map(w => 
                            <option value={w.numsemaine}>{ convertISODate(w.datedebut).toUpperCase() } - { convertISODate(w.datefin).toUpperCase() }</option>    
                        )}
                    </select>

                    <div>
                    <select value={reservationPopup.idstage} onChange={(e) => {
                        reservationController("idstage", e.target.value);
                        verifyResaPopup(); 
                    }}>
                        {stages.map(w => 
                            <option value={w.idstage}>{ w.libelle } - {(w.nbplaces == null) ? "INDISP." : <>{w.nbplaces} p. rest.</>}</option>    
                        )}
                    </select>

                    <select value={reservationPopup.idoption} onChange={(e) => {
                        reservationController("idoption", e.target.value);    
                        verifyResaPopup();
                    }}>
                        <option>Aucune option</option>    

                        {options.map(w => 
                            <option value={w.idoption}>{ w.libelle } ({w.horairedebut}) - {(w.nbplaces == null) ? "INDISP." : <>{w.nbplaces} p. rest.</>}</option>    
                        )}
                    </select>
                    </div>
                {
                    messagesPopup.map(i => { return (<p className={styles.msgPopup}>{ i }</p>)})
                }
                <button type="button" className="actionButton" onClick={updateReservation}>ENREGISTRER</button>
            </form>
            </Popup> : null }

            {
                editPricePopup ? <Popup title="Modifier le prix de la commande" close={() => setEditPricePopup(false)}>
                    <div style={{ width: "90%"}}>
                        <InputForm value={updatedPrice} type="number" libelle="Prix de la commande" name="prixtotal" placeholder="Prix total" required={true} controller={(k, v) => setUpdatedPrice(v)} ></InputForm>
                    </div> 
                    <button type="button" className="actionButton" onClick={async () => {

                        await Commande.updatePrix(getToken(), details.codecommande, updatedPrice);
                        setEditPricePopup(false);

                        const res = await Commande.getCommandeById(getToken(), id);
                        setDetails(res);
                    
                    }}>ENREGISTRER</button>
                </Popup> : null
                
            }

{
            confirmPaiementPopup ? <Popup title="Confirmer le paiement" close={() => setConfirmPaiementPopup(false)}>
                <div style={{ width: "90%"}}>
                    <p style={{ margin: 20, fontSize: '14pt'}}>Confirmer le paiement de <b>{paiementToConfirm.prix}</b> € en <b>{paiement_code[paiementToConfirm.moyenpaiement]}</b> ({paiementToConfirm.infos}) ?</p>
                </div> 
                <button type="button" className="actionButton" onClick={() => {
                    Commande.confirmPaiement(getToken(), paiementToConfirm.refpaiement).then(() => {
                        setConfirmPaiementPopup(false);

                        refreshPaiements();
                        
                    });
                }}>CONFIRMER</button>    

            </Popup> : null
                
        }

        {
            cancelPaiementPopup ? <Popup title="Annuler le paiement" close={() => setCancelPaiementPopup(false)}>
                <div style={{ width: "90%"}}>
                    <p style={{ margin: 20, fontSize: '14pt'}}>Annuler le paiement de <b>{paiementToCancel.prix}</b> € en <b>{paiement_code[paiementToCancel.moyenpaiement]}</b> ? Cette action est irréversible !</p>
                </div> 
                <button type="button" className="actionButtonNegative" onClick={() => {
                    Commande.cancelPaiement(getToken(), paiementToCancel.refpaiement).then(() => {
                        setCancelPaiementPopup(false);

                        refreshPaiements();
                        
                    });
                }}>ANNULER LE PAIEMENT</button>    

            </Popup> : null
                
        }
 
    </>);
}

export default CommandeDetails;