import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Option {

    static async getAllOptions() {
        const res = await axios.get(api_endpoint + '/options');
        return res.data;
    }

    static async createOption(token, libelle) {
        const res = await axios.post(api_endpoint + '/admin/options/create',
            {token, libelle});
        return res.data.idoption;
    }

    static async getOptionById(id) {
        const res = await axios.get(api_endpoint + '/options/' + id);
        return res.data;    
    }

    static async getStockByWeek(id) {
        const res = await axios.get(api_endpoint + '/options/' + id + '/stock');
        return res.data;
    }

    static async setStock(token, idoption, stock) {
        const res = await axios.post(api_endpoint + '/admin/options/' + idoption + '/update/stock',
            {token, stock});
        return res.data;
    }

    static async updateOption(token, infos) {
        const res = await axios.post(api_endpoint + '/admin/options/' + infos.idoption + '/update',
            {token, ...infos});
        return res.data;
    }

    static async deleteOption(token, id) {
        const res = await axios.post(api_endpoint + '/admin/options/' + id + '/delete',
            {token});
        return res.data;
    }

    static async getAllOptionsWithDispo(week) {
        const res = await axios.get(api_endpoint + "/options?week=" + week);
        return res.data;
    }

}

export default Option;