import React, { useEffect } from "react";
import image from '../ycbl-logo.png';

import style from "./header-step.module.css";
import { useNavigate } from "react-router-dom";

import { UilShoppingCart } from '@iconscout/react-unicons'

function HeaderStep(props) {

    const navigate = useNavigate();

    const steps = ["Choix de la semaine", "Age et niveau", "Stages", "Options", "Stagiaire", "Panier", "Paiement", "Confirmation"];

    useEffect(() => {
        document.title = props.title + " | YCBL";
    }, [props.title]);

    return (
        <div class="header-bar" className={style.header}>
            <span className={style.box}><img onClick={() => navigate('/')} className={style.logo} src={image}></img></span>
            <span className={style.box}><h1>{ props.title }</h1></span>
            <span className={style.box}><a onClick={() => navigate('/panier')} ><UilShoppingCart />VOTRE PANIER</a></span>
        </div>
    );
}

export default HeaderStep;