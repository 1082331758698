import axios from 'axios';

const api_endpoint = require('../config').api_endpoint;
//const api_endpoint = "http://10.192.62.84:3001/api";

class Stage {

    static async getAllStages() {
        const res = await axios.get(api_endpoint + '/stages');
        return res.data;
    }

    static async getAllStagesAvailablesWithWeekAndAge(week, age) {
        const res = await axios.get(api_endpoint + "/stages?weeks[]=" + week + "&age=" + age);
        return res.data;
    }


}

export default Stage;