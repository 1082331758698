
import React from "react";
import style from "./options.module.css";
import { api_endpoint } from "../config";

const jourWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

function Options(props) {


    const click = function() {
        props.onclick(props.option);
    }

    return (
        <div onClick={props.option.nbplaces > 0 && props.option.forcecomplet == false ? click : null} className={style.root} style={{ backgroundImage: "url(" + '/uploads/' + props.option.image + ")"}}>
            
            {props.option.nbplaces == 0 || props.option.forcecomplet == true ? <div className={style.soldout}>
                <p>COMPLETE</p>
            </div> : null }

            <div className={style.imageContainer}>
                <div className={style.image} style={{ backgroundImage: "url(" + api_endpoint +  '/uploads/get/' + props.stage.image + ")"}}></div>
                <div className={style.image} style={{ backgroundImage: "url(" + api_endpoint +  '/uploads/get/' + props.option.image + ")"}}></div>
            </div>
            <main>
                <div className={style.headerInfo}>
                    <div>
                        <p className={style.libelleStage}>{props.stage.libelle} + </p>
                        <h3>OPTION {props.option.libelle.toUpperCase()}</h3>
                    </div>
                    <p className={style.dispoInfo}><b>{props.option.nbplaces}</b> places restantes</p>
                </div>

                <div className={style.tagList}>
                    <p className={style.tag}>TOUS NIVEAUX</p>
                </div>
                
                <p>{props.option.description}</p>

                <div className={style.footerInfo}>
                    <div className={style.momentInfo}>
                        <p className={style.horaireInfo}>{props.option.horairedebut.substring(0, 5)} - {props.option.horairefin.substring(0, 5)}</p>
                    </div>
                    <p className={style.prixInfo}>{props.option.prix} €</p>
                </div>
            </main>
        </div>
    );
}

export default Options;