import React from "react";
import { useState } from "react";
import styles from './home.module.css';
import { useEffect, useContext } from "react";
import Home from "../controller/home";
import { AdminPanelContext } from ".";


import {
    Chart as ChartJS,
    CategoryScale,
    PointElement,
    LineElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,

  } from 'chart.js';
  import { Line, Bar } from 'react-chartjs-2';
import Commande from "../controller/commande";
  
  
  ChartJS.register(
    CategoryScale,
    PointElement,
    LineElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }


function HomeAdmin(props) {

    let paiement_code = {
        "CARD": "CARTE BANCAIRE",
        "CARD_ONLINE": "CARTE BANCAIRE (en ligne)",
        "CASH": "ESPECES",
        "TRANSFER": "VIREMENT BANCAIRE",
        "CHEQUE": "CHEQUES",
        "CHEQUEHOLY": "CHEQUE VACANCES",
        "REFUND": "REMBOURSEMENT",
        "REDUC_ADH": "REDUCTION ADHERENT",
        "REDUC_PERSO": "REDUCTION PERSONNALISEE",
        "BON_CADEAU": "BON CADEAU",
    }

    const { setTitle, getToken, year } = useContext(AdminPanelContext);
    setTitle("Accueil");

    const [payDatas, setPayDatas] = useState({});
    const [commandesEvolution, setCommandesEvolution] = useState([]);
    const [commandesEvolutionLastYear, setCommandesEvolutionLastYear] = useState([]);
    const [caEvolution, setCaEvolution] = useState([]);
    const [caEvolutionLastYear, setCaEvolutionLastYear] = useState([]);
    const [comparePercentage, setComparePercentage] = useState(0);
    const [caThisWeek, setCaThisWeek] = useState(0);
    const [caReferenceLastYear, setCaReferenceLastYear] = useState(0);

    const weeknum = new Date().getWeek();


    const refresh = async function() {
        const res = await Home.getPayStats(getToken(), year);
        const res2 = await Home.getPayStatsByWeek(getToken(), year);
        const res3 = await Home.getPayStatsByWeek(getToken(), year - 1);

        const res4 = await Commande.getNbByWeeks(getToken(), year);
        const res5 = await Commande.getNbByWeeks(getToken(), year - 1);

        res.all = res.all.sort((a, b) => b.total - a.total);


        setPayDatas(res);
        setCommandesEvolutionLastYear(res5);

        if(year == new Date().getFullYear()) {

            setCommandesEvolution(res4.filter(item => item.weeknum < weeknum));
            setCaEvolution(res2.filter(item => item.weeknum < weeknum));
            setCaThisWeek(res2.find(item => item.weeknum == weeknum).ca - res2.find(item => item.weeknum == weeknum - 1).ca);
        } else {
            setCommandesEvolution(res4);
            setCaEvolution(res2);
        }

        if(res3) {

            setCaEvolutionLastYear(res3);

            setCaReferenceLastYear(res3.find(item => item.weeknum == weeknum).ca - res3.find(item => item.weeknum == weeknum - 1).ca);

            const week = new Date().getWeek();
            const delta = res2.find(item => item.weeknum == (weeknum - 1)).ca - res3.find(item => item.weeknum == (weeknum - 1)).ca;
            const percentageDelta = Math.round((delta / res3.find(item => item.weeknum == (weeknum - 1)).ca) * 100);
            setComparePercentage(percentageDelta);
        }

        


    }


    useEffect(() => {
        refresh();
    }, [year]);


    return(<div>


        <div style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>
            <p>SEMAINE {weeknum}</p>
            <p style={{ fontSize: 40 }}>
            <span style={{ color: (caThisWeek > caReferenceLastYear ? 'green' : 'orange') }}> { caThisWeek + ' €' } </span>/ { caReferenceLastYear + ' €'}
            </p>
            <div style={{
                width: 'inherit',
                border: '1px solid ' + (caThisWeek > caReferenceLastYear ? 'green' : 'orange'),
                height: 12,
                margin: '10px 0'
            }}>
                <div style={{
                    width: (Math.abs(caThisWeek) / Math.abs(caReferenceLastYear)) * 100 + '%',
                    maxWidth: '100%',
                    backgroundColor: (caThisWeek > caReferenceLastYear ? 'green' : 'orange'),
                    height: '100%',
                }}></div>
            </div>
            <p style={{ fontWeight: 'normal', fontSize: 14, maxWidth: 300}}>Comparaison du chiffre d'affaire de la semaine {weeknum} par rapport à l'année dernière.</p>
        </div>

        <div style={{ display: 'flex', alignItems: 'center'}}>
        <div style={{ width: '80%'}}>
            <Line options={{
            responsive: true,
            plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chiffre d\'affaires par semaine',
            },
            },
        }} data={{
                labels: Array.from({length: 52}, (_, i) => i + 1),
                datasets: [{
                    fill: true,
                    label: 'CA ' + year,
                    data: caEvolution.map(item => item.ca),
                    borderColor: '#2197B1',
                    backgroundColor: 'rgba(33, 151, 177, 0.2)',
                }, (caEvolutionLastYear.length > 0 ? {
                    fill: true,
                    label: 'CA ' + (year - 1),
                    data: caEvolutionLastYear.map(item => item.ca),
                    borderColor: '#33333355',
                
                } : {})]
            }} width={400} />

            </div>
            <div style={{ marginLeft: 10, fontSize: 50, fontWeight: 'bold', minWidth: '300px', color: (comparePercentage > 0 ? 'green' : 'red')}}>
                { (comparePercentage > 0 ? "+" : "") + comparePercentage } %
                <p style={{ fontSize: 14, color: 'black' }}>Par rapport à l'année dernière</p>
            </div>
        </div>

        <div style={{ padding: 30}}>
    <Bar options={{
        responsive: true,
        plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Nombre de commandes par semaine',
        },
        }
    }} data={{
        labels: Array.from({length: 52}, (_, i) => i + 1),
        datasets: [{
            label: 'Nb. commandes ' + (year-1),
            data: commandesEvolutionLastYear.map(item => item.nb),
            backgroundColor: '#33333355',
        }, 
        {
            label: 'Nb. commandes ' + year,
            data: commandesEvolution.map(item => item.nb),
            backgroundColor: '#2197B1',
        }]
    }} width={500} />
    </div>
    <div style={{ padding: 30}}>

        <Bar options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
              },
              title: {
                display: true,
                text: 'Chiffre d\'affaires par moyen de paiement',
              },
            }
        }} data={{
            labels: payDatas.all ? payDatas.all.map(item => paiement_code[item.moyenpaiement]) : [],
            datasets: [{
                label: 'CA ' + year,
                data: payDatas.all ? payDatas.all.map(item => item.total) : [],
                backgroundColor: '#2197B1',
            }]
        }} width={500} />
    </div>

        
    </div>);


}

export default HomeAdmin;