import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Upload {

    static async uploadFile(token, file) {

        const formData = new FormData();
        formData.append("image", file);

        const res = await axios.post(api_endpoint + "/admin/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": token
            },
        });

        return res.data;
    }

}

export default Upload;