import axios from 'axios';
const api_endpoint = require('../config').api_endpoint;

class Week {

    static async getWeeks() {
        try {
            const res = await axios.get(api_endpoint + '/semaines');
            return res.data;
        }
        catch(err) {
            throw err;
        }
    }

}

export default Week;