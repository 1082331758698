import React, { useContext, useEffect, useState } from "react";
import { AdminPanelContext } from ".";
import Commande from "../controller/commande";

import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNavigate } from "react-router-dom";
import styles from './commandes.module.css';
import InputForm from "../../components/inputform.module";
import { UilCheckCircle } from '@iconscout/react-unicons';
import { UilMoneyInsert } from '@iconscout/react-unicons'

function Commandes (props) {

    const {setTitle, getToken, year} = useContext(AdminPanelContext);
    const [showWaitingOnly, setShowWaitingOnly] = useState(false);
    const navigate = useNavigate();

    const [columns] = useState([
        { field: 'status', headerName: 'Etat', sortable: true, valueFormatter: (p) => (p.value == 'CONFIRMED' ? "🟢" : "🟠") },
        // je veux trier par id décroissant
        { field: 'idcommande', headerName: 'ID', sortable: true, sort: 'desc'  },
        { field: 'datecommande', headerName: 'Date de la commande', sortable: true, valueFormatter: (d) => new Date(d.value).toLocaleString() },
        { field: 'nom' },
        { field: 'prenom' },
        { field: 'prixtotal', headerName: 'Prix Total (€)', sortable: true, valueFormatter: (p) => p.value + '€' },
        { field: 'codecommande', headerName: 'Référence commande', filter: true },

    ]);

    const [datas, setDatas] = useState([]);

    useEffect(() => {
        setTitle("Commandes");

        Commande.getCommandes(getToken(), year).then(res => {
            setDatas(res);
            console.log(res);
        }); 
    }, [year]);

    const handleRowClicked = (e) => {
        const idCommande = e.data.idcommande;
        navigate('details/' + idCommande);
    }

    return(<>
        <h1>Liste des commandes</h1>
        <div className={styles.searchbar}>
            <InputForm type="text" libelle="Recherche de commande" name="search" placeholder="Par nom, prénom, référence, paiement, stagiaires, etc..." controller={ (a, query) => {
                if(query.length >= 3) {
                    Commande.searchCommandes(getToken(), query, year).then(res => {
                        setDatas(res);
                    });
                }
                else if(query.length == 0) {
                    Commande.getCommandes(getToken(), year).then(res => {
                        setDatas(res);
                    });
                }
            } } ></InputForm>

            <div style={{ display: "flex", alignItems: "center"}}>
                <input type="checkbox" id="noconfirmOnly" name="noconfirmOnly" onChange={(e) => {
                    setShowWaitingOnly(e.target.checked);
                }}></input>
                <label for="noconfirmOnly">COMMANDES EN ATTENTES UNIQUEMENT</label>
            </div>

        </div>
        <table>
            <tr>
                <th>Statut</th>
                <th>Num. de commande</th>
                <th>Date commande</th>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Prix total</th>
            </tr>
            { datas.length == 0 && <tr><td colSpan="6">Aucune commande trouvée</td></tr>}

            {
                datas.filter(i => !showWaitingOnly || i.status != 'CONFIRMED' ).map((d) => {
                    return(
                        <tr onClick={() => handleRowClicked({data: d})} className={styles.tableHover}>
                            <td style={{ display: "flex", alignItems: "center"}}>{d.status == 'CONFIRMED' ? "🟢" : "🟠"}&nbsp;{ d.traitee ? <UilCheckCircle /> : " " }&nbsp;<span style={{ color: 'red'}}>{d.resteapayer < 0 ? <UilMoneyInsert /> : null}</span></td>
                            <td>{d.refcommande}</td>
                            <td>{new Date(d.datecommande).toLocaleString()}</td>
                            <td>{d.nom}</td>
                            <td>{d.prenom}</td>
                            <td>{d.prixtotal}€</td>
                        </tr>
                    );
                })
            }
        </table>
        
    </>);

}

export default Commandes;