import React, { useContext, useEffect, useState } from "react";
import { Stepper, Step, StepLabel, StepButton } from '@mui/material';


import WeeksChoice from './weeks-choice.module';
import StagesChoice from './stages-choice.module';
import OptionsChoice from './options-choice.module';
import AgeChoice from './age-choice.module';
import Recapitulatif from './recapitulatif.module';
import Informations from './informations.module';
import Reservation from "../controller/reservations.controller";
import PopUp from '../components/popup-message.module';
import { Global } from "../App";
import { useNavigate } from "react-router-dom";
import StagiaireInfos from "./stagiaire.module";
import Panier from "./panier.module";

import Order from "../models/order";
import Commande from "../controller/commandes.controller";
import HeaderStep from "../ScreenPart/header-step.module";

import styles from './creator-form.module.css';

export const FormContext = React.createContext("formcontext");

function CreatorForm(props) {

    const { showError} = useContext(Global); 

    const [step, setStep] = useState(0);

    const steps = ["Choix de la semaine", "Age", "Stages", "Options", "Stagiaire"];


    const navigate = useNavigate();

    const [parameters, setParameters] = useState({
      weeks: [],
      age: 1,
      stage: {},
      option: {},
      stagiaire: {}
    });
  

    const nextStep = async function(result, next = step + 1) {
        if(step == 0) {
          console.log(result);
          setParameters({...parameters, weeks: result});
        }
    
        if(step == 1) {
          setParameters({...parameters, age: result});
        }
    
        if(step == 2) {
          setParameters({...parameters, stage: result});
        }
    
        if(step == 3) {
          setParameters({...parameters, option: result});
        }

        if(step == 4) {  
          parameters.stagiaire = result;
          try {
            if(!localStorage.getItem('codecommande')) {
              // on créé la commande
              let result = await Commande.createCommande();
              localStorage.setItem("codecommande", result.commandeHash);
            }
            await Reservation.createReservation(localStorage.getItem('codecommande'), parameters.stagiaire, parameters.stage.numsemaine, parameters.stage.idstage, (parameters.option) ? parameters.option.idoption : null);
            navigate('/panier/');
          }
          catch(err) {
            showError("Une erreur est survenue. Veuillez réessayer.");
            return;
          }
        
        }
    
    
        setStep(next);
    }

    const goBack = function () {
      if(step > 0) {
        setStep(step - 1);
      }
    }

    const showStep = function() {
        try {
          switch(step) {
            case 0:
              return (<WeeksChoice />)
            case 1:
              return (<AgeChoice />)
            case 2:
              return (<StagesChoice />)
            case 3:
              return (<OptionsChoice />)
            case 4: 
              return (<StagiaireInfos/>)
            
          }
        }
        catch(err) {
          console.log("azertty")
        }
      }

    

    return(
      <>
        <HeaderStep title={"Réservation d'un stage"}></HeaderStep>

        <div className={styles.header} class="stepper">

          <Stepper activeStep={step} alternativeLabel>
          {steps.map((label, index) => (
              <Step key={label}>
                  <StepButton onClick={() => setStep(index)}>{label}</StepButton>
              </Step>
          ))}
          </Stepper>
        </div>
        {step > 0 ? 
          <a className={styles.backButton} onClick={goBack}>{"◀︎ RETOUR"}</a> : null }

        <FormContext.Provider value={{ parameters, nextStep }}>
            {showStep()}
        </FormContext.Provider>
      </>
    );


}

export default CreatorForm;