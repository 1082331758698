import './App.css';
import { useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate, redirect } from 'react-router-dom';


import Informations from './screens/informations.module';
import PopUpMessage from './components/popup-message.module';
import CreatorForm from './screens/creator-form.module';
import ReservationIndex from './screens/reservation-index.module';
import Panier from './screens/panier.module';
import Home from './screens/home.module';
import MainAdmin from './backoffice/screens';
import LoginAdmin from './backoffice/screens/login.module';

export const Global = React.createContext("global");

function App() {

  const [message, setMessage] = useState(null);
  const [titlePage, setTitlePage] = useState("...");


  const showError = (err) => {
    if(err.data) {
      setMessage(err.data.error);
    }
    else {
      setMessage("Une erreur est survenue, veuillez réessayer.")
      setTimeout(() => setMessage(null), 5000);
    }
  }

  return (
    <Router>
      <div className="root">

        <Global.Provider value={{ showError }}>
          <Routes>
            <Route exact path="/" element={<Home></Home>}></Route>
            <Route exact path="/creator" element={ <CreatorForm /> }></Route>
            <Route exact path="/panier" element={ <Panier /> }></Route>
            <Route exact path="/confirmation" element={ <Informations /> }></Route>
            <Route path="/order/:ref" element={ <ReservationIndex/> }></Route>
            <Route exact path="/admin/login" element={ <LoginAdmin/> }></Route>
            <Route path="/admin/*" element={ <MainAdmin/> }></Route>
          </Routes>
        </Global.Provider>

        {message ? <PopUpMessage content={message} /> : null}

      </div>
      <div className='footer'></div>

    </Router>
  );
}

export default App;
