import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Home {

    static async getPayStats(token, year) {
        const res = await axios.post(api_endpoint + '/admin/paiements/statistics/' + year, {
            token
        });
        return res.data;
    }

    static async getPayStatsByWeek(token, year) {
        const res = await axios.post(api_endpoint + '/admin/paiements/statisticsWeekByWeek/' + year, {
            token
        });
        return res.data;
    }
    

}

export default Home;