import React, { useContext, useEffect, useRef, useState } from "react";
import { Global } from "../App";
import Reservation from "../controller/reservations.controller";
import styles from './paiement.module.css';
import { UilCreditCard } from '@iconscout/react-unicons'
import ancv_logo from '../images/ANCV_logo.png';
import cash_logo from '../images/cash.png';
import cheque_logo from '../images/cheque.png';
import virement_logo from '../images/virement.png';
import InputForm from "../components/inputform.module";
import Commande from "../controller/commandes.controller";

function PaiementModule(props) {

    const formRef = useRef(null);

    const [ buttonVisible, setButtonVisible ] = useState(true); 
    const [ etransaction, setEtransaction ] = useState(true);
    const { showError } = useContext(Global);
    const [mode, setMode] = useState('');
    const [ payInfos, setPayInfos ] = useState('');
    const [montant,setMontant] = useState(0);
    const [isValid, setIsValid] = useState(false);
    const [paiements, setPaiements] = useState([]);

    let verifInterval = null;

    window.onload = function () {
        window.onmessage = function (e) {
            console.log(e.data);
            if(e.data.resultPaiement) {
                if(e.data.resultPaiement != '00000') {
                    setButtonVisible(true);
                    setMode('');
                }
                else {
                    clearInterval(verifInterval);
                    verifInterval = setInterval(props.refresh, 2000);
                }
            }
        }
    }

    useEffect(() => {
        // On récupère les paiements déjà effectués
        Commande.getAllPaiements(props.code).then(paiements => {
            setPaiements(paiements);
        });
    });

    useEffect(function () {

        // On récupère s'il y a déjà eu des paiements
        Commande.getAllPaiements(props.code).then(p => {

            setMontant(0);
            setPayInfos('');
            setIsValid(false);
            

        });


    }, [mode]);

    const initCardPaiement = function () {
        setButtonVisible(false);

        Reservation.eTransactionDatas(props.code, props.prix)
            .then(datas => {
                setMode('CARD_ONLINE');
                setEtransaction(datas);
                setTimeout(() => formRef.current.submit(), 500);
                clearInterval(verifInterval);
                verifInterval = setInterval(props.refresh, 2000);
                
            })
            .catch(err => {console.log(err); showError(err)});    
    }

    const payController = (key, value) => {
        if(key == "infos") {
            setPayInfos(value);
        }
        else if(key == "montant") {
            setMontant(value);
        }

        setIsValid(montant > 0);
    }

    const createPaiement = () => {
        Commande.addPaiement(props.code, mode, payInfos, montant).then(() => {
            props.refresh();
            setMode('');
        });
    }

    const displayMode = function () {

        if(mode == 'CARD_ONLINE') {
            return (<iframe id="payframe" name="etransaction"></iframe>);
        }

        const getContent = () => {
            if(mode == 'CHEQUEHOLY') {
                return (<>
                    <img src={ancv_logo} style={{ padding: 5}} />
                    <h2>CHÈQUES VACANCES</h2>
                    <p className="explication">Le ou les chèques vacances ANCV doivent être valide est remis au club YCBL sous 7 jours.</p>
                    <InputForm type="text" value={payInfos} libelle="Numéro ANCV (séparés par une ',')" name="infos" autoFocus={true} controller={ payController } required={true}></InputForm>
                </>)
            }
            else if(mode == 'CHEQUE') {
                return (<>
                    <img src={cheque_logo} style={{ padding: 5}} />
                    <h2>CHÈQUES</h2>
                    <p className="explication">Le chèque doivent être valide et correctement rempli avec l'ordre <b>YCBL</b> et remis au club YCBL sous 7 jours.</p>
                    <InputForm type="text" value={payInfos} libelle="Numéro du chèque" name="infos" autoFocus={true} controller={ payController } required={true}></InputForm>
                </>)
            }
            else if(mode == 'CASH') {
                return (<>
                    <img src={cash_logo} style={{ padding: 5}} />
                    <h2>ESPECES / CASH</h2>
                    <p className="explication">Les espèces doivent être remis au club YCBL sous 7 jours</p>
                </>)
            }
            else if(mode == 'TRANSFER') {
                return (<>
                    <img src={virement_logo} style={{ padding: 5}} />
                    <h2>VIREMENT SEPA</h2>
                    <p className="explication">Vous devez effectuez le virement sur le RIB suivant : <b>FR76 1810 6008 1093 8942 8705 083</b> dans un délai de 7 jours. Veillez à indiquer votre référence de commande mentionnée ci-dessus dans les informations du virement.</p>
                </>)
            }
        }

        return (<div className={styles.payBox}>
            <hr></hr>
            <a onClick={() => setMode('')}>◀︎ RETOUR CHOIX MOYEN PAIEMENT</a>
            {getContent()}
            <InputForm min={1} max={props.prix} value={montant} type="number" libelle="Montant du paiement" name="montant"  controller={ payController } required={true}></InputForm>
            <button className="actionButton" onClick={ () => createPaiement() } disabled={!isValid}>AJOUTER PAIEMENT</button>
            <button className="actionButtonNegative" onClick={ () => setMode('') } >ANNULER PAIEMENT</button>
        </div>)
    }   


    return (
        <div className={styles.choix}>
            <form ref={formRef} method="POST" action={etransaction.LINK} target="etransaction">
                <input type="hidden" name="PBX_SITE" value={etransaction.PBX_SITE} />
                <input type="hidden" name="PBX_RANG" value={etransaction.PBX_RANG} />
                <input type="hidden" name="PBX_IDENTIFIANT" value={etransaction.PBX_IDENTIFIANT} />
                <input type="hidden" name="PBX_TOTAL" value={etransaction.PBX_TOTAL} />
                <input type="hidden" name="PBX_DEVISE" value={etransaction.PBX_DEVISE} />
                <input type="hidden" name="PBX_CMD" value={etransaction.PBX_CMD} />
                <input type="hidden" name="PBX_SOURCE" value={etransaction.PBX_SOURCE} />
                <input type="hidden" name="PBX_PORTEUR" value={etransaction.PBX_PORTEUR} />
                <input type="hidden" name="PBX_RETOUR" value={etransaction.PBX_RETOUR} />
                <input type="hidden" name="PBX_REPONDRE_A" value={etransaction.PBX_REPONDRE_A} />
                <input type="hidden" name="PBX_EFFECTUE" value={etransaction.PBX_EFFECTUE} />
                <input type="hidden" name="PBX_REFUSE" value={etransaction.PBX_REFUSE} />
                <input type="hidden" name="PBX_ANNULE" value={etransaction.PBX_ANNULE} />
                <input type="hidden" name="PBX_HASH" value={etransaction.PBX_HASH} />
                <input type="hidden" name="PBX_TIME" value={etransaction.PBX_TIME} />
                <input type="hidden" name="PBX_HMAC" value={etransaction.PBX_HMAC} />
            </form>

            <div className={styles.choixButtons} style={{ display: ((mode === "") ? 'flex' : 'none')}}>
                <button className="actionButton" onClick={() => initCardPaiement()}><UilCreditCard  size="30" /> CARTE BANCAIRE</button>
                <hr />
                <p className={styles.txt}>Vous pouvez aussi payer sur place par espèces, chèque, chèques vacances. <br /><b>Attention, la commande doit être réglée sous 3 jours.</b><br /> Après cela elle sera automatiquement annulée.</p>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <button className="actionButton" onClick={ () => setMode("CHEQUEHOLY") } style={{display: "flex", flexDirection: "column"}}><img src={ancv_logo} style={{ padding: 5}} /> CHEQUE VACANCES</button>
                    <button className="actionButton" onClick={ () => setMode("CASH") } style={{display: "flex", flexDirection: "column"}}><img src={cash_logo} style={{ padding: 5}} /> ESPECES</button>
                    <button className="actionButton" onClick={ () => setMode("CHEQUE") } style={{display: "flex", flexDirection: "column"}}><img src={cheque_logo} style={{ padding: 5}} /> CHEQUE</button>
                    <button className="actionButton" onClick={ () => setMode("TRANSFER") } style={{display: "flex", flexDirection: "column"}}><img src={virement_logo} style={{ padding: 5}} /> VIREMENT</button>
                </div>
            </div>
            {(mode != "") ? displayMode() : null}
        </div>
    );

}


export default PaiementModule;