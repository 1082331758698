import React, { useContext, useEffect, useState } from "react";
import Stage from "../controller/stages";
import styles from "./editor.module.css";
import { AdminPanelContext } from ".";
import { useNavigate, useParams } from "react-router-dom";
import InputForm from "../../components/inputform.module";
import convertISODate from "../../Utilitaire/date";
import Popup from "../../components/popup.module";
import { UilEdit } from '@iconscout/react-unicons';
import { UilPause } from '@iconscout/react-unicons';
import { UilPlay } from '@iconscout/react-unicons';
import WeekPopupSelector from "../../components/week-popup-selector.module";
import OptionPopupSelector from "../../components/option-popup-selector.module";
import Upload from "../controller/uploadService";
import { api_endpoint } from "../../config";

function StagesEditor(props) {

    const { setTitle, getToken, year } = useContext(AdminPanelContext);
    setTitle("Éditeur de stage");

    const navigate = useNavigate();

    const [stage, setStage] = useState({});
    const [stocks, setStocks] = useState([]);
    const [options, setOptions] = useState([]);
    const [optionPopup, setOptionPopup] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [showPopupStock, setShowPopupStock] = useState(false);
    const [showPopupOption, setShowPopupOption] = useState(false);
    const [stockPopup, setStockPopup] = useState({});
    const [newWeekPopup, setNewWeekPopup] = useState(false);
    const [newOptionPopup, setNewOptionPopup] = useState(false);
    const [isSaved, setIsSaved] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        Stage.getStageById(id).then((stg) => {
            setStage(stg);
            testIfValid(stg);
        });
        
        Stage.getOptions(id).then(o => {
            setOptions(o);
        });

        Stage.getStockByWeek(id, new Date().getFullYear()).then((st) => {
            setStocks(st);
        });

    }, []);


    let formulaireController = function(name, value) {
        const formuReponses = stage;
        formuReponses[name] = value;

        setStage({...formuReponses});
        console.log(name, stage.libelle);

        // On vérifie que tout les champs soient corrects
        testIfValid(formuReponses);
        setIsSaved(false);
    
    }

    const popupStockController = function(name, value) {
        if(name == "stock") {
            setStockPopup({...stockPopup, nbplaces: value});
        }
    }

    const popupOptionController = function(name, value) {
        if(name == "prix") {
            setOptionPopup({...optionPopup, prix: value});
        }
    }

    const testIfValid = function(formuReponses) {

        let v = true;

        for(const champ in formuReponses) {
            v &= (formuReponses[champ] !== null)
        }

        setIsValid(v);
    }

    const editStock = function(stock) {
        setStockPopup(stock);
        setShowPopupStock(true);
        setIsSaved(false);
    }

    const editOption = function(option) {
        setOptionPopup(option);
        setShowPopupOption(true);
        setIsSaved(false);
    }

    const saveModif = async function() {
        await Stage.updateStage(getToken(), stage);
        await Stage.setStock(getToken(), id, stocks);
        await Stage.updateOptions(getToken(),id, options);
        setIsSaved(true);
        window.location.reload();
    }

    const displayStock = function(stock) {
        return (
            <tr style={(stock.forcecomplet) ? { backgroundColor: "rgba(232, 149, 144, 0.5)"} : null}>
                <td>{ convertISODate(stock.datedebut).toUpperCase() } - { convertISODate(stock.datefin).toUpperCase() } </td>
                <td>{ (stock.forcecomplet ? <UilPause /> : <UilPlay />) }</td>
                <td>{ stock.nbplaces }</td>
                <td onClick={() => editStock(stock)} className={styles.btnEdit}><UilEdit></UilEdit></td>
            </tr>
        )
    }

    const displayOption = function(option) {
        return (
            <tr>
                <td onClick={() => window.open('/admin/options/editor/' + option.idoption, '_blank')} className={styles.optionLink}>{ option.libelle } ({ option.horairedebut }) </td>
                <td>{ option.prix } €</td>
                <td onClick={() => editOption(option)} className={styles.btnEdit}><UilEdit></UilEdit></td>
            </tr>
        )
    }

    const saveStock = () => {
        const stocksTmp = stocks;

        const s = stocksTmp.find(i => i.numsemaine === stockPopup.numsemaine);
        s.nbplaces = stockPopup.nbplaces;
        s.forcecomplet = stockPopup.forcecomplet;

        setStocks([...stocksTmp]);
        setShowPopupStock(false);
        setIsSaved(false);

    }

    const saveOption = () => {
        const optionsTmp = options;

        const o = optionsTmp.find(i => i.idoption === optionPopup.idoption);
        o.prix = optionPopup.prix;

        setOptions([...optionsTmp]);
        setShowPopupOption(false);
        setIsSaved(false);

    }

    const deleteOption = () => {
        const optionsTmp = options.filter(i => i.idoption !== optionPopup.idoption);

        setOptions([...optionsTmp]);
        setShowPopupOption(false);
        setIsSaved(false);

    }

    const deleteSemaine = () => {
        const stocksTmp = stocks.filter(i => i.numsemaine !== stockPopup.numsemaine);

        setStocks([...stocksTmp]);
        setShowPopupStock(false);
        setIsSaved(false);

    }

    const deleteStage = async () => {
        if(window.confirm("Êtes-vous sûr de vouloir supprimer ce stage ?")) {
            await Stage.deleteStage(getToken(), id);
            navigate('/admin/stages');
        }
    }

    const handleUpload = async (e) => {
        const file = e.target.files[0];

        const res = await Upload.uploadFile(getToken(), file);
        const filename = res.filename;

        setStage({...stage, image: filename});
        setIsSaved(false);
        console.log("upload", res);
    }

    return (
        <div className={styles.root}>
            <div className={styles.topHead}>
                <a onClick={() => navigate('/admin/stages')}>◀︎ RETOUR LISTE STAGES / OPTIONS</a>
                <h1>{ stage.libelle }</h1>
                <p className={styles.alertMsg}>{ !isSaved ? "MODIFICATIONS NON ENREGISTRÉES !" : null }</p>
            </div>
            <div className={styles.content}>
                <form className={styles.formu}>
                    <div>
                        <label className={styles.img} for="imgupload">
                            <input accept="image/png, image/jpeg, image/jpg" id="imgupload" name="imgupload" type="file" onChange={handleUpload} style={{display: "none"}}></input>
                            { stage.image ? <img src={api_endpoint + "/uploads/get/" + stage.image} alt="Image du stage" /> : null }
                        </label>
                    </div>
                    <div>
                        <InputForm type="text" modifIndicator={true} value={stage.libelle} libelle="Nom du stage" name="libelle" placeholder="MOUSAILLON" controller={ formulaireController } required={true}></InputForm>
                    </div>
                    <div>
                        <InputForm type="time" modifIndicator={true} value={stage.horairedebut} libelle="Horaire de début" name="horairedebut" placeholder="13:00"  controller={ formulaireController } required={true}></InputForm>
                        <InputForm type="time" modifIndicator={true} value={stage.horairefin} libelle="Horaire de fin" name="horairefin" placeholder="17:00" controller={ formulaireController } required={true}></InputForm>
                    </div>
                    <div style={{ display: "flex", justifyContent: "stretch"}}>
                        <span className={styles.span}>
                            <label className={styles.label}>Jour de début (*)</label>
                            <select className="selectInput" style={{ flexGrow: 1}} name="jourdebut" value={stage.jourdebut} onChange={(e) => formulaireController("jourdebut", e.target.value)}>
                                <option value="1">LUNDI</option>
                                <option value="2">MARDI</option>
                                <option value="3">MERCREDI</option>
                                <option value="4">JEUDI</option>
                                <option value="5">VENDREDI</option>
                                <option value="6">SAMEDI</option>
                                <option value="7">DIMANCHE</option>
                            </select>
                        </span>
                        <span className={styles.span}>
                            <label className={styles.label}>Jour de fin (*)</label>
                            <select className="selectInput" style={{ flexGrow: 1}} name="jourfin" value={stage.jourfin} onChange={(e) => formulaireController("jourfin", e.target.value)}>
                                <option value="1">LUNDI</option>
                                <option value="2">MARDI</option>
                                <option value="3">MERCREDI</option>
                                <option value="4">JEUDI</option>
                                <option value="5">VENDREDI</option>
                                <option value="6">SAMEDI</option>
                                <option value="7">DIMANCHE</option>
                            </select>
                        </span>
                    </div>
                    <div>
                        <InputForm type="number" modifIndicator={true} value={stage.agemin} libelle="Age minimum" name="agemin" placeholder="6"  controller={ formulaireController } required={true}></InputForm>
                        <InputForm type="number" modifIndicator={true} value={stage.agemax} libelle="Age maximum" name="agemax" placeholder="150" controller={ formulaireController } required={true}></InputForm>
                    </div>
                    <div>
                        <InputForm type="textarea" modifIndicator={true} value={stage.description} libelle="Description du stage" name="description" placeholder="Décrivez le contenu du stage ici..." controller={ formulaireController } required={true}></InputForm>
                    </div>
                    <div>
                        <InputForm type="text" modifIndicator={true} value={stage.supports} libelle="Support de navigation" name="support" placeholder="Catamaran" controller={ formulaireController } required={true}></InputForm>
                        <InputForm type="text" modifIndicator={true} value={stage.niveau} libelle="Niveau du stage" name="niveau" placeholder="Débutant" controller={ formulaireController } required={true}></InputForm>
                    </div>
                    <div>
                        <InputForm type="number" modifIndicator={true} value={stage.prix} libelle="Prix du stage (sans option)" name="prix" placeholder="135" controller={ formulaireController } required={true}></InputForm>
                    </div>
                </form>
                <div className={styles.stock}>
                    <table>
                        <tr>
                            <th>SEMAINE</th>
                            <th>STATUT</th>
                            <th>Nb. PLACES</th>
                            <th>EDIT.</th>
                        </tr>
                        { stocks.length === 0 ? <tr><td colSpan="4">Aucune semaine de stage n'est définie pour l'instant.</td></tr> : null}
                        { stocks.map(s => displayStock(s)) }
                    </table>
                    <a className={styles.addBtn} onClick={() => setNewWeekPopup(true)}>+ AJOUTER UNE SEMAINE</a>

                    <table>
                        <tr>
                            <th>OPTION</th>
                            <th>PRIX DU PACK</th>
                            <th>EDIT.</th>
                        </tr>
                        { options.length === 0 ? <tr><td colSpan="3">Aucune option n'a été ajouté pour l'instant.</td></tr> : null}
                        { options.map(o => displayOption(o)) }
                    </table>
                    <a className={styles.addBtn} onClick={() => setNewOptionPopup(true)}>+ AJOUTER UNE OPTION</a>
                </div>
                
            </div>

            <div className={styles.bottomBtn}>
                <button type="button" className="actionButtonNegative" onClick={deleteStage} >SUPPRIMER LE STAGE</button>
                <button type="button" className="actionButton" onClick={saveModif} >ENREGISTER LES MODIFICATIONS</button>
            </div>
            { showPopupStock ? <Popup title={"Gestion stock"} close={() => setShowPopupStock(false)}> 
                <h1>{ convertISODate(stockPopup.datedebut).toUpperCase() } - { convertISODate(stockPopup.datefin).toUpperCase() }</h1>
                <div className={styles.pauseBtn}>
                    <span>EN PAUSE</span>
                    <input type="checkbox" checked={stockPopup.forcecomplet} onChange={(e) => {
                        setStockPopup({...stockPopup, forcecomplet: e.target.checked})
                    }}></input>
                </div>
                <InputForm type="number" min={0} value={stockPopup.nbplaces} libelle="Nombre de places disponibles" name="stock" placeholder="14" required={true} controller={popupStockController} disabled={stockPopup.forcecomplet}></InputForm>
                <a onClick={deleteSemaine}>SUPPRIMER LA SEMAINE</a>
                <button type="button" className="actionButton" onClick={saveStock}>ENREGISTRER</button>
            </Popup> : null }


            { showPopupOption ? <Popup title={"Gestion option"} close={() => setShowPopupOption(false)}> 
                <h1>{ optionPopup.libelle } ({ optionPopup.horairedebut })</h1>

                <InputForm type="number" min={0} value={optionPopup.prix} libelle="Prix du pack (stage + option)" name="prix" placeholder="214" required={true} controller={popupOptionController}></InputForm>
                <a onClick={deleteOption}>SUPPRIMER LE PACK</a>
                <button type="button" className="actionButton" onClick={saveOption}>ENREGISTRER</button>
            </Popup> : null }


            {
                newWeekPopup ? <WeekPopupSelector close={() => setNewWeekPopup(false) } onResponse={
                    (rep) => {
                        setStocks([...stocks, {
                            numsemaine: rep.numsemaine,
                            datedebut: rep.datedebut,
                            datefin: rep.datefin,
                            forcecomplet: false,
                            nbplaces: 0
                        }])
                    }
                } /> : null
            }

            {
                newOptionPopup ? <OptionPopupSelector close={() => setNewOptionPopup(false)} onResponse={
                    (rep) => {
                        // Si cette option est déjà ajouté alors on ouvre directement l'éditeur
                        const o = options.find(i => i.idoption === rep.idoption);
                        if(o) {
                            editOption(o); 
                        }
                        else {
                            setOptions([...options, {...rep, prix: 0}]);
                            editOption({...rep, prix: 0});
                        } 
                    }
                } /> : null
            }
            

        </div>
    )

}

export default StagesEditor;