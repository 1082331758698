import React, { useContext } from "react";
import ListStages from "../ScreenPart/list-stages.module";
import HeaderStep from "../ScreenPart/header-step.module";
import { FormContext } from "./creator-form.module";

function StagesChoice(props) {

    const { parameters, nextStep } = useContext(FormContext);

    parameters.weeks.sort(function(a, b) {
        return a.numsemaine - b.numsemaine;
    });

    const listWeeks = parameters.weeks.map(w => 
        <ListStages week={w} age={parameters.age} onchoice={ nextStep }/>
    );


    return (
        <div className="root">
            <p className="explication">Choisissez le stage que vous souhaitez effectuer pendant la semaine choisie afin de le réserver. Cliquez sur celui qui vous intéresse.</p> 
            { listWeeks }
        </div>
    );

}

export default StagesChoice;