import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminPanelContext } from ".";
import ListeAppel from "../ScreenPart/liste-appel.module";
import styles from './stagiaires-lists.module.css';
import Reservation from "../controller/reservations";
import convertISODate from "../../Utilitaire/date";
import { UilAngleRight, UilAngleLeft } from '@iconscout/react-unicons'
import FileSaver from "file-saver";
import excelJS from 'exceljs';

function StagiairesList(props) {

    const { setTitle, getToken, year } = useContext(AdminPanelContext);
    setTitle("Réservations et liste stagiaires");

    const [datasR, setDatasR] = useState([]);
    const [semaine, setSemaine] = useState({ numsemaine: 0, datedebut: "", datefin: ""});

    useEffect(() => {
        Reservation.getAllListeStagiaireByStage(getToken(), year).then(res => {

            setDatasR(res);

            setSemaine({
                numsemaine: res[0].numsemaine,
                datedebut: convertISODate(res[0].datedebut).toUpperCase(),
                datefin: convertISODate(res[0].datefin).toUpperCase()
            });
        });
    }, [year]);

    function displaySemaines() {
        const items = [];

        const sem = datasR.find(i => i.numsemaine == semaine.numsemaine);

        if(sem) {
            console.log("sem", sem.datedebut);

            sem.stages.forEach(item => {
                if(item.nbPlacesTotal !== null) {
                    items.push(<ListeAppel libelle={item.stage} nbPlacesTotal={item.nbPlacesTotal} items={item.stagiaires} sem={sem}/>);
                }
            });
        }

        return items;
    }

    async function exportStagiaires() {
        const stagiaires = await Reservation.getInformationsStagiairesForExcel(getToken(), semaine.numsemaine);

        const workbook = new excelJS.Workbook();
        const worksheet = workbook.addWorksheet('Stagiaires');

        const date = new Date();

        worksheet.columns = [
            { header: 'Nom', key: 'nom', width: 20 },
            { header: 'Prénom', key: 'prenom', width: 20 },
            { header: 'Sexe', key: 'sexe', width: 5 },
            { header: 'Date de naissance', key: 'datenaissance', width: 20 },
            { header: 'Adresse', key: 'adresse', width: 40 },
            { header: 'Code postal', key: 'codepostal', width: 10 },
            { header: 'Ville', key: 'ville', width: 15 },
            { header: 'Pays', key: 'pays', width: 15 },
            { header: 'Téléphone', key: 'tel', width: 15 }
        ];

        stagiaires.forEach(stagiaire => {
            worksheet.addRow(stagiaire);
        });
    
        worksheet.getRow(1).eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCCCCC' }
            };
            cell.font = {
                bold: true
            }
        });
    
        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), "export-stagiaires-sem" + semaine.numsemaine + ".xlsx");

    }

    async function exportAutorisationsStagiaires() {
        const stagiaires = await Reservation.getInformationsStagiairesForExcel(getToken(), semaine.numsemaine);

        const canRentrerSeul = stagiaires.filter(i => i.rentrerseul === true);
        const cantRentrerSeul = stagiaires.filter(i => i.rentrerseul === false);

        const workbook = new excelJS.Workbook();
        const worksheet = workbook.addWorksheet('Autorisations');

        const date = new Date();

        worksheet.columns = [
            { header: 'Nom', key: 'nom', width: 20 },
            { header: 'Prénom', key: 'prenom', width: 20 },
            { header: 'Droit à l\'image', key: 'droitimage', width: 20 },
            { header: 'Peut rentrer seul', key: 'rentrerseul', width: 20 },
            { header: 'Nom de la personne autorisé à récupérer', key: 'nom_accompagnant', width: 20 },
        ];

        canRentrerSeul.forEach(stagiaire => {
            worksheet.addRow(stagiaire);
            // mettre en vert les stagiaires qui peuvent rentrer seuls
            worksheet.lastRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF00FF00' }
                };
            });
        });

        cantRentrerSeul.forEach(stagiaire => {
            worksheet.addRow(stagiaire);

            // mettre en rouge les stagiaires qui ne peuvent pas rentrer seuls
            worksheet.lastRow.eachCell((cell, number) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFAA00' }
                };
            });
        });

        worksheet.getRow(1).eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCCCCC' }
            };
            cell.font = {
                bold: true
            }
        });

        const buffer = await workbook.xlsx.writeBuffer();
        FileSaver.saveAs(new Blob([buffer]), "export-autorisations-stagiaires-sem" + semaine.numsemaine + ".xlsx");
    }

    function changeSemaine(d) {
        //const sem = datasR.find(i => i.numsemaine == semaine.numsemaine + d);
        const indexSemActuel = datasR.findIndex(i => i.numsemaine == semaine.numsemaine);
        const sem = datasR[indexSemActuel + d];

        if(sem) {
            setSemaine({
                numsemaine: sem.numsemaine,
                datedebut: convertISODate(sem.datedebut).toUpperCase(),
                datefin: convertISODate(sem.datefin).toUpperCase()
            });
        }
    }

    return(
        <div className={styles.root}>
            <div className={styles.header}><h2><span className={styles.changeDateBtn}><UilAngleLeft size={50} onClick={() => changeSemaine(-1)}/></span> { semaine.datedebut} - { semaine.datefin } <span className={styles.changeDateBtn}><UilAngleRight size={50} onClick={() => changeSemaine(1)}/></span></h2></div>
            <div className={styles.toolBox}>
                
                <a onClick={exportStagiaires}><UilAngleRight /> EXPORTER LES STAGIAIRES SEM. {semaine.numsemaine}</a>
                <a onClick={exportAutorisationsStagiaires}><UilAngleRight /> EXPORTER LES AUTORISATIONS SEM. {semaine.numsemaine}</a>
            </div>
            
            <div className={styles.stagelist}>
                { displaySemaines() }
            </div>
        </div>
    );

}

export default StagiairesList;