import React, { useContext, useState } from "react";
import { FormContext } from "./creator-form.module";
import InputForm from "../components/inputform.module";

function AgeChoice(props) {


    const [formuReponses, setFormuReponses] = useState({  
        age: null
    });

    const [isValid, setIsValid] = useState(false);

    const { nextStep } = useContext(FormContext);

    let formulaireController = function(name, value) {
        formuReponses[name] = value;

        setFormuReponses(formuReponses);

        let v = true;

        // On vérifie que tout les champs soient corrects
        for(const champ in formuReponses) {
            v &= (formuReponses[champ] !== null)
        }

        setIsValid(v);
    }

    return (
        <div className="root">
            <p className="explication">Veuillez entrer l'âge du stagiaire afin de filtrer les offres disponibles. ATTENTION, la date de naissance exacte vous sera demandée notamment pour l'émission du passeport voile (licence de voile, assurance) et borne le stage adéquate. **NE PAS INDIQUER DE FAUSSE DATE, encadrement et assurance spécifique aux âges. Au besoin : 04 79 25 21 66</p>
            <div>
                <InputForm type="number" libelle="Age du stagiaire" name="age" autoFocus={true} controller={ formulaireController } required={true}></InputForm>
            </div>
            <button className="actionButton" onClick={() => nextStep(formuReponses.age) } disabled={!isValid}>SUIVANT</button>

        </div>
    );

}

export default AgeChoice;