import React, { useContext, useEffect, useState } from "react";
import Stage from "../controller/stages";
import styles from "./stages-list.module.css"
import { AdminPanelContext } from ".";
import Option from "../controller/options";
import { useNavigate } from "react-router-dom";
import { api_endpoint } from "../../config";
import Popup from "../../components/popup.module";
import InputForm from "../../components/inputform.module";

function StagesList(props) {

    const { setTitle, getToken } = useContext(AdminPanelContext);
    setTitle("Liste des stages");

    const navigate = useNavigate();

    const [stages, setStages] = useState([]);
    const [options, setOptions] = useState([]);
    const [newStagePopup, setNewStagePopup] = useState(false);
    const [libelleNewStage, setLibelleNewStage] = useState("");
    const [newOptionPopup, setNewOptionPopup] = useState(false);
    const [libelleNewOption, setLibelleNewOption] = useState("");

    useEffect(() => {
        Stage.getAllStages().then((stg) => {
            setStages(stg);
        });

        Option.getAllOptions().then((op) => {
            setOptions(op);
        });
    }, []);

    
    const displayStageOrOption = (it, itsOption) => {
        return (
            <div className={styles.stageItem} onClick={ () => (itsOption) ? navigate('/admin/options/editor/' + it.idoption) : navigate('/admin/stages/editor/' + it.idstage) }>
                <div className={styles.imageBackground} style={{
                    backgroundImage: "url(" + api_endpoint + "/uploads/get/"+ it.image  +")"
                }} ></div>
                <div className={styles.stageItemContent}>
                    <p className={styles.stageLibelle}>{ it.libelle }</p>
                    <p className={styles.stageTime}>{ it.horairedebut.substr(0,5) } - { it.horairefin.substr(0,5) }</p>
                </div>
            </div>
        );
    }

    const createStage = () => {
        Stage.createStage(getToken(), libelleNewStage).then((id) => {
            navigate('/admin/stages/editor/' + id);
        });
    }

    const createOption = () => {
        Option.createOption(getToken(), libelleNewOption).then((id) => {
            navigate('/admin/options/editor/' + id);
        });
    }

    return (<>
        <div className={styles.root}>
            <h1>Liste des stages</h1>

            <div className={styles.stagesList}>

                { stages.map(s => displayStageOrOption(s)) }
            </div>

            <a className={styles.newBtn} onClick={() => setNewStagePopup(true)}>+ NOUVEAU STAGE</a>


            <h1>Liste des options</h1>

            <div className={styles.stagesList}>

                { options.map(s => displayStageOrOption(s, true)) }

            </div>

            <a className={styles.newBtn} onClick={() => setNewOptionPopup(true)}>+ NOUVELLE OPTION</a>

        </div>

        {
            newStagePopup ? <Popup title="Nouveau stage" close={() => setNewStagePopup(false)}>
                <div style={{ width: "90%"}}>
                    <InputForm value={libelleNewStage} type="text" libelle="Nom du stage" name="libelle" placeholder="Nom du stage" required={true} controller={(key, value) => setLibelleNewStage((value) ? value.toUpperCase() : "")} ></InputForm>
                </div> 
                <button type="button" className="actionButton" onClick={createStage}>ENREGISTRER</button>
            </Popup> : null
            
        }

{
            newOptionPopup ? <Popup title="Nouvelle option" close={() => setNewOptionPopup(false)}>
                <div style={{ width: "90%"}}>
                    <InputForm value={libelleNewOption} type="text" libelle="Nom de l'option" name="libelle" placeholder="Nom de l'option" required={true} controller={(key, value) => setLibelleNewOption((value) ? value.toUpperCase() : "")} ></InputForm>
                </div> 
                <button type="button" className="actionButton" onClick={createOption}>ENREGISTRER</button>
            </Popup> : null
            
        }
        </>
    );

}

export default StagesList;