import axios from 'axios';

const api_endpoint = require('../../config').api_endpoint;

class Commande {

    static async getCommandes(token, year) {
        const res = await axios.post(api_endpoint + '/admin/commandes/?year=' + year, {
            token
        });
        return res.data;
    }

    static async getCommandesConfirmed(token) {
        const res = await axios.post(api_endpoint + '/admin/commandes/confirmed', {
            token
        });
        return res.data;
    }

    static async updatePrix(token, codecommande, prix) {
        const res = await axios.post(api_endpoint + '/admin/commandes/updatePrix/' + codecommande, {
            token,
            prix
        });
        return res.data;
    }

    static async getCommandeById(token, id) {
        const res = await axios.post(api_endpoint + '/admin/commandes/getId/' + id, {
            token
        });
        return res.data;
    }

    static async getReservations(codecommande) {
        const res = await axios.get(api_endpoint + '/commandes/' + codecommande + '/reservations');
        return res.data;
    }

    static async getPaiements(codecommande) {
        const res = await axios.get(api_endpoint + '/paiements/' + codecommande + '/all');
        return res.data;
    }

    static async addPaiement(token, codecommande, moyenpaiement, prix = 0) {
        const res = await axios.post(api_endpoint + '/admin/paiements/new/' + moyenpaiement + '/' + prix + '/' + codecommande, { token })
        return res.data;
    }

    static async getPaiementToBeConfirmed(token) {
        const res = await axios.post(api_endpoint + '/admin/paiements/toBeConfirmed', { token })
        return res.data;
    }

    static async deleteCommande(token, idcommande) {
        const res = await axios.post(api_endpoint + '/admin/commandes/delete/' + idcommande, { token });
        return res.data;
    }

    static async markAsTraitee(token, codecommande) {
        const res = await axios.post(api_endpoint + '/admin/commandes/markAsTraitee/' + codecommande, { token });
        return res.data;
    }

    static async searchCommandes(token, query, year) {
        const res = await axios.post(api_endpoint + '/admin/commandes/search?year=' + year, { token, query });
        return res.data;
    }

    static async confirmPaiement(token, refpaiement) {
        const res = await axios.post(api_endpoint + '/admin/paiements/confirm/' + refpaiement, { token });
        return res.data;
    }

    static async cancelPaiement(token, refpaiement) {
        const res = await axios.post(api_endpoint + '/admin/paiements/cancel/' + refpaiement, { token });
        return res.data;
    }

    static async relanceCommande(token, mail, codecommande) {
        const res = await axios.post(api_endpoint + '/admin/commandes/sendRelance/' + codecommande + '/' + mail, { token });
        return res.data;
    }

    static async extractMailFromStageAndWeek(token, idstage, week) {
        const res = await axios.post(api_endpoint + '/admin/commandes/extractMails/' + week + '/' + idstage, { token });
        return res.data;
    }

    static async getNbByWeeks(token, year) {
        const res = await axios.post(api_endpoint + '/admin/commandes/getNbByWeeks/' + year, { token });
        return res.data;
    }
}

export default Commande;