import axios from 'axios';

const api_endpoint = require('../config').api_endpoint;
//const api_endpoint = "http://10.192.62.84:3001/api";

class Commande {

    static async createCommande() {
        const res = await axios.get(api_endpoint + '/commandes/create');
        return res.data;
    }

    static async getReservations(codecommande) {
        const res = await axios.get(api_endpoint + '/commandes/' + codecommande + '/reservations');
        return res.data;
    }

    static async getAllPaiements(codecommande) {
        const res = await axios.get(api_endpoint + '/paiements/' + codecommande + '/all');
        return res.data;
    }

    static async addPaiement(codecommande, moyenpaiement, infos, prix = 0) {
        const res = await axios.post(api_endpoint + '/paiements/new/' + moyenpaiement + '/' + prix + '/' + codecommande, { infos })
        return res.data;
    }

    static async getCommande(codecommande) {
        const res = await axios.get(api_endpoint + '/commandes/' + codecommande);
        return res.data;    
    }

    static async addClient(codecommande, client) {
        const res = await axios.post(api_endpoint + '/commandes/' + codecommande + '/addClient', {
            client
        });
        return res.data;       
    }
}

export default Commande;