import React, { useContext, useEffect } from "react";
import ListOptions from "../ScreenPart/list-options.module";
import HeaderStep from "../ScreenPart/header-step.module";
import { FormContext } from "./creator-form.module";

function OptionsChoice(props) {

    const { parameters, nextStep } = useContext(FormContext);

    return (
        <div className="root">
            <p className="explication">Vous pouvez choisir une option qui complètera votre stage. Celle-ci est <b>facultative</b>. Pour choisir une option, cliquez sur celle-ci.</p>
            <button onClick={() => nextStep(null) } className="actionButton">Continuer sans option</button>
            <ListOptions stage={parameters.stage} onchoice={ nextStep } ></ListOptions>
        </div>
    );

}

export default OptionsChoice;