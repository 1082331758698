import React from "react";
import style from './popup-message.module.css';

function PopUpMessage(props) {

    return (
        <div className={ style.root }>
            <p>{ props.content }</p>
            <div className={ style.button }></div>
        </div>
    );

}


export default PopUpMessage;