import React, { useState } from "react";
import styles from './popup.module.css';

function Popup(props) {

    return (<>
        <div className={styles.background}>
            <div className={styles.popup}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{ props.title ? props.title.toUpperCase() : "" }</h2>
                    <span className={styles.closeBtn} onClick={props.close}>×</span>
                </div>
                { props.children }
            </div>
        </div>
    </>)

}

export default Popup;